import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'country-flag',
    templateUrl: 'country-flag.component.html',
    styleUrls: ['country-flag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CountryFlagComponent {
  @Input() code: string;
  @Input() tooltip: string;
}
