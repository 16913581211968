import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { Exception } from '@k2/common/entities-state/types';
import { isNotNil, Subscriptions } from '@k2/common/helpers';
import { Paginator } from '@k2/common/pagination/paginator';
import { formSpecToFields } from '@k2/common/k2-forms/fields';
import { first, map } from 'rxjs/operators';
import { ClientAssignmentComponent } from '@k2/staff/content/clients/client/assignment/client-assignment.component';
import { FormDialogService } from '@k2/common/k2-forms/form-dialog/form-dialog.service';
import { ServiceIdentity } from '@k2/staff/content/clients/client/assignment/common/service-identity';
import { ExceptionsFieldsComponent } from '@k2/staff/content/clients/client/assignment/common/exceptions/exceptions-fields.component';
import { AppStore } from '@k2/common/state/services/app-store';
import { StaffAppState } from '@k2/staff/staff-app-state';
import { getAssignment } from '@k2/common/assignments-state/reducers';
import { select } from '@ngrx/store';

@Component({
    selector: 'exceptions-section',
    templateUrl: 'exceptions-section.component.html',
    styleUrls: ['exceptions-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ExceptionsSectionComponent implements OnDestroy {

  @Input() exceptions: Exception[];
  @Input() serviceIdentity: ServiceIdentity;
  @Input() serviceId: number;
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();

  underConfirmation = false;
  subscriptions = new Subscriptions();
  paginator = Paginator.custom({ pageSize: 5, keepOffsetAfterLengthChange: true });
  isAssignmentEncrypted: boolean = true;

  constructor(private dialog: FormDialogService,
              private backend: Backend,
              private assignmentRef: ClientAssignmentComponent,
              store: AppStore<StaffAppState>) {
    store.pipe(
      select(getAssignment(this.assignmentRef.identity.assignmentId)),
      first(isNotNil),
      map(res => res.is_assignment_encrypted)
    ).subscribe(data => this.isAssignmentEncrypted = data);
  }

  addException(serviceId: number) {
    const fields = this.backend.services
      .fetchNewExceptionForm(this.assignmentRef.identity, serviceId)
      .pipe(
        map(data => data.form),
        formSpecToFields
      );

    this.dialog
      .openForm({
        title: 'New exception',
        submitLabel: 'Add exception',
        fields,
        fieldsCmp: {
          component: ExceptionsFieldsComponent,
          inputs: { fields }
        },
        onSubmit: value => this.backend.services
          .addNewException(this.assignmentRef.identity, serviceId, value).toPromise()
      }).subscribe(response => this.update.emit(response));
  }

  deleteException(exceptionId) {
    this.delete.emit({ ...this.assignmentRef.identity, serviceId: this.serviceId, exceptionId });
  }

  updateExceptions(exceptionId) {
    const fields = this.backend.services
      .updateException(this.assignmentRef.identity, this.serviceId, exceptionId)
      .pipe(map(data => data.form), formSpecToFields);

    this.dialog
      .openForm({
          title: 'Edit exception',
          submitLabel: 'Save changes',
          fields,
          fieldsCmp: {
            component: ExceptionsFieldsComponent,
            inputs: { fields }
          },
          onSubmit: value => this.backend.services
            .updateExceptionEvent(this.assignmentRef.identity, this.serviceId, exceptionId, value).toPromise()
        }
      ).subscribe(exceptions => this.update.emit(exceptions));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
