import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Fields } from '@k2/common/k2-forms-state/types';
import { Observable } from 'rxjs';

@Component({
    selector: 'exceptions-fields',
    templateUrl: 'exceptions-fields.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ExceptionsFieldsComponent {
  readonly fields: Observable<Fields>;
}
