import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PartnerStats } from '@k2/common/entities-state/types';

@Component({
    selector: 'partner-stats',
    templateUrl: 'partner-stats.component.html',
    styleUrls: ['partner-stats.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PartnerStatsComponent {
  @Input() stats: PartnerStats[];
}


