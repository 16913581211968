import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'action',
    templateUrl: 'action.component.html',
    styleUrls: ['action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ActionComponent {
  @Input('type') explicitType?: ActionType;

  @ViewChild('content', { static: true }) content: ElementRef;

  get type(): string {
    return this.explicitType || this.content.nativeElement.innerHTML.trim();
  }
}

export type ActionType = 'ok' | 'done' | 'cancel' | 'edit' | 'remove';
