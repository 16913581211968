import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Wraps the child text into colored box to highlight its appearance.
 */
@Component({
    selector: 'highlighted',
    templateUrl: 'highlighted.component.html',
    styleUrls: ['highlighted.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HighlightedComponent {
  @Input() type: HighlightType = 'default';
}

type HighlightType = 'default' | 'ok' | 'warn' | 'error';
