import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
    selector: 'tabs',
    templateUrl: 'tabs.component.html',
    styleUrls: ['tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TabsComponent {
  @Input() items: TabItem[];
  @Input() padding = '14px';

  get linkStyle() {
    return {
      'padding-left': this.padding,
      'padding-right': this.padding
    };
  }

  constructor(private cdRef: ChangeDetectorRef) {}

  public setItems(items: TabItem[]) {
    this.items = items;
    this.cdRef.detectChanges();
  }
}

export interface TabItem {
  readonly label: string;
  readonly link: string;
  readonly icon: string;
  readonly exact?: boolean;
}
