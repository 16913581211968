import { ApiClient } from '@k2/common/backend/api-client';
import {
  AssignmentIdentity,
  ServiceExceptionIdentity,
  ServiceExceptionStatusEnum,
  SubsectionIdentity,
  SubsectionItemIdentity
} from '@k2/common/entities-state/types';
import {
  ServiceCollectionIdentity,
  ServiceIdentity
} from '@k2/staff/content/clients/client/assignment/common/service-identity';
import { VendorIdentity } from '@k2/staff/content/clients/client/assignment/common/vendor-identity';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormSpec } from '@k2/common/k2-forms-state/types';
import * as PATH from "node:path";

export class AssignmentServicesEndpoint {
  constructor(private api: ApiClient) {}

  fetchService = (serviceIdentity: ServiceIdentity) => {
    const path = toServicePath(serviceIdentity);
    return this.api.get(path);
  };

  updateAssigmentServiceNotes = (serviceIdentity: ServiceIdentity, notes: string) => {
    const path = `${toServicePath(serviceIdentity)}/notes`;
    const data = { notes };

    return this.api.patch(path, data);
  };

  updateExceptionStatus = (
    identity: ServiceExceptionIdentity,
    status: ServiceExceptionStatusEnum
  ) => {
    const data = { status };
    const path = `/clients/${identity.client}/assignment/${identity.assignment}/service/${identity.service}/exception/${identity.id}/status`;
    return this.api.patch(path, data);
  };
  updateExceptionEvent = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: any,
    exceptionId: number,
    data: any
  ) => {

    const path = `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/exception/${exceptionId}`;
    return this.api.patch(path, data);
  };
  updateException = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: any,
    exceptionId: number,
  ) => {
    const path = `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/exception/${exceptionId}`;
    return this.api.get(path);
  };

  deleteException = ({ clientId, assignmentId }: AssignmentIdentity, serviceId: number, exceptionId: number) => {
    const path = `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/exception/${exceptionId}`;

    return this.api.delete(path);
  }
  updateCostSaving = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: any,
    costSavingId: number,
  ) => {
    const path = `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/cost_savings/${costSavingId}`;
    return this.api.get(path);
  };

  updateCostSavingEvent = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: any,
    costSavingId: number,
    data: any
  ) => {

    const path = `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/cost_savings/${costSavingId}`;
    return this.api.patch(path, data);
  };

  deleteCostSaving = ({ clientId, assignmentId }: AssignmentIdentity, serviceId: number, exceptionId: number) => {
    const path = `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/cost_savings/${exceptionId}`;

    return this.api.delete(path);
  }

  updateStatusElement = (
    collectionIdentity: ServiceCollectionIdentity,
    elementId: string,
    milestoneId: string
  ) => {
    const data = { milestone_id: milestoneId };
    const path = `${toCollectionPath(collectionIdentity)}/status/${elementId}`;

    return this.api.patch(path, data);
  };

  fetchDocumentCandidates = (serviceIdentity: ServiceIdentity) => {
    const path = `${toServicePath(serviceIdentity)}/documents/candidates`;

    return this.api.get(path).pipe(map(payload => payload.documents));
  };

  deleteServiceDocument = (serviceIdentity: ServiceIdentity, documentId: number) => {
    const path = `${toServicePath(serviceIdentity)}/documents/${documentId}`;

    return this.api.delete(path);
  };

  linkDocuments = (serviceIdentity: ServiceIdentity, documentIds: number[]) => {
    const path = `${toServicePath(serviceIdentity)}/documents`;
    const data = { selected_documents: documentIds };

    return this.api.put(path, data);
  };
  fetchNewExceptionForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: any
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/exception/form`
    );
  };

  addNewException = ({ clientId, assignmentId }: AssignmentIdentity, serviceId: number , data : any) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/exceptions`, data);
  }
  fetchNewCostSavingForm = (
    { clientId, assignmentId }: AssignmentIdentity,
    serviceId: any
  ) => {
    return this.api.get(
      `/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/cost_savings/form`
    );
  };

  addNewCostSaving = ({ clientId, assignmentId }: AssignmentIdentity, serviceId: number , data : any) => {
    return this.api.post(`/clients/${clientId}/assignment/${assignmentId}/service/${serviceId}/cost_savings`, data);
  }
  getVendorRegions = (collectionIdentity: ServiceCollectionIdentity) => {
    const path = `${toCollectionPath(collectionIdentity)}/vendor/regions`;

    return this.api.get(path);
  };

  searchForVendors = (
    collectionIdentity: ServiceCollectionIdentity,
    regionId: number,
    name?: string,
    stateId?: number,
    vendorType?: string
  ) => {
    let path = `${toCollectionPath(collectionIdentity)}/vendor/search/region/${regionId}`;

    if (name) path += `/name/${encodeURIComponent(name)}`;
    if (stateId) path += `/state/${stateId}`;
    if (vendorType) path += `/vendorType/${vendorType}`;

    return this.api.get(path).pipe(map(response => response.vendors));
  };

  getNewVendorInitiationDetails = (collection: ServiceCollectionIdentity, vendorId: number) => {
    const path = `${toCollectionPath(collection)}/vendor/${vendorId}/details`;

    return this.api.get(path);
  };

  getDefaultVendorDetails = (
    collection: ServiceCollectionIdentity,
    vendorId: number,
    serviceInstanceId: string
  ) => {
    const path = `${toCollectionPath(collection)}/vendor/${vendorId}/details/${serviceInstanceId}`;

    return this.api.get(path);
  };

  addVendor = (collectionIdentity: ServiceCollectionIdentity, data) => {
    const path = `${toCollectionPath(collectionIdentity)}/vendors`;

    return this.api.post(path, data);
  };

  getVendorDetails = (vendorIdentity: VendorIdentity) => {
    const path = toVendorDetailsPath(vendorIdentity);

    return this.api.get(path).pipe(map(res => res.vendor));
  };

  patchVendorDetails = (vendorIdentity: VendorIdentity, data) => {
    const path = toVendorDetailsPath(vendorIdentity);

    return this.api.patch(path, data);
  };

  patchInitiationDetails = (vendorIdentity: VendorIdentity, data) => {
    const path = toVendorPath(vendorIdentity) + `/${vendorIdentity.instanceId}`;

    return this.api.patch(path, data);
  };

  getVendorInitiateForm = (vendorIdentity: VendorIdentity): Observable<FormSpec> => {
    const path = `${toVendorPath(vendorIdentity)}/${vendorIdentity.instanceId}/initiate/form`;

    return this.api.get(path).pipe(map(response => response.form));
  };

  getImmigrationVendorInitiateForm = (vendorIdentity: VendorIdentity): Observable<FormSpec> => {
    const path = `${toVendorPath(vendorIdentity)}/${vendorIdentity.instanceId}/immigration/initiate/form`;

    return this.api.get(path).pipe(map(response => response.form));
  };

  postVendorInitiateForm = (vendorIdentity: VendorIdentity, data) => {
    const path = `${toVendorPath(vendorIdentity)}/${vendorIdentity.instanceId}/initiate`;

    return this.api.post(path, data);
  };

  postImmigrationVendorInitiateForm = (vendorIdentity: VendorIdentity, data) => {
    const path = `${toVendorPath(vendorIdentity)}/${vendorIdentity.instanceId}/immigration/initiate`;

    return this.api.post(path, data);
  };

  initiateVendorOffPlatform = (vendorIdentity: VendorIdentity) => {
    const path = `${toVendorPath(vendorIdentity)}/${vendorIdentity.instanceId}/offplatform`;

    return this.api.post(path, {});
  };

  deleteVendor = (vendorIdentity: VendorIdentity) => {
    const path = toVendorPath(vendorIdentity) + `/${vendorIdentity.instanceId}`;

    return this.api.delete(path);
  };

  fetchNewCollectionForm = (serviceIdentity: ServiceIdentity) => {
    const path = `${toServicePath(serviceIdentity)}/collection/new`;

    return this.api.get(path).pipe(map(payload => payload.form));
  };

  addNewCollection = (serviceIdentity: ServiceIdentity, data) => {
    const path = `${toServicePath(serviceIdentity)}/collections`;

    return this.api.post(path, data);
  };

  addNewSpecificCollection = (collectionIdentity: ServiceCollectionIdentity, data) => {
    const path = `${toServicePath(collectionIdentity.service)}/collections/${
      collectionIdentity.collectionId
    }`;

    return this.api.post(path, data);
  };

  fetchCollectionForm = (collectionIdentity: ServiceCollectionIdentity) => {
    const path = toCollectionPath(collectionIdentity);

    return this.api.get(path).pipe(map(payload => payload.form));
  };

  updateCollection = (collectionIdentity: ServiceCollectionIdentity, data) => {
    const path = toCollectionPath(collectionIdentity);

    return this.api.patch(path, data);
  };

  deleteCollection = (collectionIdentity: ServiceCollectionIdentity) => {
    const path = toCollectionPath(collectionIdentity);

    return this.api.delete(path);
  };

  updateInitiationDetails = (vendorIdentity: VendorIdentity, data) => {
    const path = `${toVendorPath(vendorIdentity)}/${vendorIdentity.instanceId}`;

    return this.api.patch(path, data);
  };

  getNewCollectionForm = (service: ServiceIdentity) => {
    const PATH = toServicePath(service) + '/collection/new';

    return this.api.get(PATH).pipe(map(response => response.form));
  };

  getCollectionForm = (collection: ServiceCollectionIdentity) => {
    const PATH = toCollectionPath(collection);

    return this.api.get(PATH).pipe(map(response => response.form));
  };

  patchCollection = (collection: ServiceCollectionIdentity, data) => {
    const PATH = toCollectionPath(collection);

    return this.api.patch(PATH, data);
  };

  getNewTenancyLeaseForm = (subsection: SubsectionIdentity) => {
    return this.getNewSubsectionItemForm(subsection);
  };

  addNewTenancyLease = (subsection: SubsectionIdentity, data) => {
    return this.addNewSubsectionItem(subsection, data);
  };

  getTenancyLeaseForEdit = (item: SubsectionItemIdentity) => {
    const PATH = this.toSubsectionItemPath(item) + '/edit';

    return this.api.get(PATH).pipe(map(response => response.form));
  };

  getTenancyLeaseForRenew = (item: SubsectionItemIdentity) => {
    const PATH = this.toSubsectionItemPath(item) + '/renew';

    return this.api.get(PATH).pipe(map(response => response.form));
  };

  patchTenancyLease = (item: SubsectionItemIdentity, data) => {
    return this.patchSubsectionItem(item, data);
  };

  deleteTenancyLease = (item: SubsectionItemIdentity) => {
    const PATH = this.toSubsectionItemPath(item);

    return this.api.delete(PATH);
  };

  renewTenancyLease = (subsection: SubsectionIdentity, data) => {
    const PATH = this.toSubsectionPath(subsection);

    return this.api.post(PATH, data);
  };

  tenancyServiceSendToEquus = (service: ServiceIdentity) => {
    const PATH = `${toServicePath(service)}/send-to-equus`;

    return this.api.post(PATH, null);
  };

  expanseServiceSendToHessel = (service: ServiceIdentity, result) => {
    const PATH = `${toServicePath(service)}/transactions/send-to-hessel`;

    return this.api.post(PATH, result);
  };

  sendToK2Workspace = (service: ServiceIdentity) => {
    const PATH = `${toServicePath(service)}/send-to-workspace`;

    return this.api.post(PATH, true);
  };

  patchSubsectionItem = (item: SubsectionItemIdentity, data) => {
    const PATH = this.toSubsectionItemPath(item);

    return this.api.patch(PATH, data);
  };

  deleteSubsectionItem = (item: SubsectionItemIdentity) => {
    const PATH = this.toSubsectionItemPath(item);

    return this.api.delete(PATH);
  };

  getSubsectionItem = (item: SubsectionItemIdentity) => {
    const PATH = this.toSubsectionItemPath(item);

    return this.api.get(PATH).pipe(map(response => response.form));
  };

  getNewSubsectionItemForm = (subsection: SubsectionIdentity) => {
    const PATH = this.toSubsectionPath(subsection) + '/new';

    return this.api.get(PATH).pipe(map(response => response.form));
  };

  addNewSubsectionItem = (subsection: SubsectionIdentity, data) => {
    const PATH = this.toSubsectionPath(subsection);

    return this.api.post(PATH, data);
  };

  patchSubsection = (subsection: SubsectionIdentity, data) => {
    const PATH = this.toSubsectionPath(subsection);

    return this.api.patch(PATH, data);
  };

  toSubsectionItemPath(item: SubsectionItemIdentity): string {
    return this.toSubsectionPath(item.subsection) + `/item/${item.itemId}`;
  }

  toSubsectionPath(subsection: SubsectionIdentity): string {
    const { vendor } = subsection;

    return `${toVendorPath(vendor)}/instance/${vendor.instanceId}/subsection/${
      subsection.subsectionId
    }`;
  }
}

function toServicePath(service: ServiceIdentity): string {
  return `/clients/${service.clientId}/assignment/${service.assignmentId}/service/${service.id}`;
}

function toCollectionPath(collection: ServiceCollectionIdentity): string {
  return `${toServicePath(collection.service)}/collection/${collection.collectionId}`;
}

function toVendorPath(vendorIdentity: VendorIdentity): string {
  return `${toCollectionPath(vendorIdentity.collection)}/vendor/${vendorIdentity.vendorId}`;
}

function toVendorDetailsPath(vendorIdentity: VendorIdentity): string {
  return toVendorPath(vendorIdentity) + `/details/${vendorIdentity.instanceId}`;
}
