import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms a given object to array of objects,
 * where each object will have two properties with
 * a given `keyName` and `valueName`.
 *
 * EXAMPLE:
 *   const obj = { a: 1, b: 2 };
 *   transform(obj, 'name', 'value'); // [{ name: 'a', value: 1 }, { name: 'b', value: 2 }]
 */
@Pipe({
    name: 'objToArray',
    standalone: false
})
export class ObjToArray implements PipeTransform {
  transform(object, keyName = 'key', valueName = 'value'): any[] {
    return object ? Object.keys(object).map(key => ({
      [keyName]: key,
      [valueName]: object[key]
    })) : [];
  }
}
