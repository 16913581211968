import { Pipe, PipeTransform } from '@angular/core';
import { appConfig } from '@k2/common/app-config';

@Pipe({
    name: 'configProp',
    standalone: false
})
export class ConfigPropPipe implements PipeTransform {
  transform(propName: string): any {
    const value = appConfig[propName];

    if (value == null)
      throw new Error(`App configuration property with name "${propName}" doesn't exist!`);

    return value;
  }
}
