import { Component, Input } from '@angular/core';
import { Field, GroupedOptions } from '@k2/common/k2-forms-state/types';
import { WithFormControl } from '@k2/common/k2-forms/field-control/utils';
import { uniq } from 'ramda';

@Component({
    selector: 'grouped-checkboxes-control',
    templateUrl: 'grouped-checkboxes-control.component.html',
    styleUrls: ['grouped-checkboxes-control.component.scss'],
    standalone: false
})
export class GroupedCheckboxesControlComponent extends WithFormControl<number[]> {
  @Input() field: Field<number[]>;

  get groups(): GroupedOptions[] {
    return this.field.attributes.valueOptions;
  }

  areAllChecked = (group): boolean => {
    return toIds(group).every(id => this.value.includes(id));
  };

  toggleAll = group => {
    const ids = toIds(group);

    if (this.areAllChecked(group)) {
      this.value = this.value.filter(id => !ids.includes(id));
    } else {
      this.value = uniq([...this.value, ...ids]);
    }
  };
}

function toIds(group): number[] {
  return group.options.map(o => o.id);
}
