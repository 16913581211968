import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'checkboxes-tree',
    templateUrl: 'checkboxes-tree.component.html',
    styleUrls: ['checkboxes-tree.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CheckboxesTreeComponent {}
