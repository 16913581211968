import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'link-ingot',
    templateUrl: 'link-ingot.component.html',
    styleUrls: ['link-ingot.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LinkIngotComponent {
  @Input() name: string;
  @Input() link: any;
  @Output() linkClick = new EventEmitter();

  constructor(private router: Router) {}

  onLinkClick() {
    if (!this.link) {
      this.linkClick.emit();
    } else {
      this.router.navigateByUrl(`${this.router.url}/${this.link}`);
    }
  }
}
