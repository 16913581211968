import { Component, Input } from '@angular/core';

@Component({
    selector: 'status-label',
    templateUrl: 'status-label.component.html',
    styleUrls: ['status-label.component.scss'],
    standalone: false
})
export class StatusLabelComponent {
  @Input() status: {
    type_value: string;
    type_label: string;
  };
}
