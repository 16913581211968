import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { pipeValuesTo } from '@k2/common/helpers';
import { Backend } from '@k2/common/backend/backend';
import { includesParts } from '@k2/common/filters/utils';
import { isEmpty } from 'ramda';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Provides a text suggestions based on a given resource.
 *
 * Resource should be in the following format: "table.column"
 *
 * EXAMPLE:
 *   <input type="text"
 *          [matAutocomplete]="suggestion.autocomplete"
 *          [formControl]="control">
 *
 *   <text-suggestion #suggestion
 *                    resource="table.column"
 *                    [value]="control.value">
 *   </text-suggestion>
 */
@Component({
    selector: 'text-suggestion',
    templateUrl: 'text-suggestion.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TextSuggestionComponent implements OnChanges {
  @Input() resource: string;
  @Input() value: string;

  @ViewChild(MatAutocomplete, { static: true }) autocomplete: MatAutocomplete;

  items = new BehaviorSubject<string[]>([]);

  constructor(private backend: Backend) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.resource == null || isEmpty(this.value)) {
      this.items.next([]);
    } else {
      this.backend.autocomplete
        .fetchSuggestions(this.resource, this.value)
        .pipe(map(options => options.filter(option => includesParts(option, this.value))))
        .subscribe(pipeValuesTo(this.items));
    }
  }
}
