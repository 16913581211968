import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { isNotEmpty } from '@k2/common/helpers';
import { Field } from '@k2/common/k2-forms-state/types';
import { FieldValueFormatter } from '@k2/common/k2-forms/field-value/field-value-formatter';
import { ComponentSpec } from '@k2/common/ui/component-spec';

@Component({
    selector: 'field-value',
    templateUrl: 'field-value.component.html',
    styleUrls: ['field-value.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FieldValueComponent implements OnChanges {
  @Input() field: Field;
  @Input() emptyMessage = 'Not specified';

  componentSpec: ComponentSpec;

  constructor(private formatter: FieldValueFormatter) {}

  ngOnChanges(): void {
    this.componentSpec = this.formatter.format(this.field);
  }

  get hasValue(): boolean {
    const { value } = this.field.control;
    return value != null && isNotEmpty(value);
  }

  isRequired(): boolean {
    return this.field.validators.find(validator => validator.name === 'requiredValidator') != undefined;
  }
}
