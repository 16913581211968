<section>
  <div class="block-frame pad0">
    <div class="row">
      <div class="col-sm-12">
        <detailed-assignment-location
          [origin]="assignment.origin"
          [destination]="assignment.destination"
        ></detailed-assignment-location>
      </div>
    </div>

    <div class="row details" *ngIf="assignment.type_id != 1894">
      <div class="col-sm-6">
        <dl class="assignment-dates inline">
          <dt>Start date:</dt>
          <dd>
            <date [iso]="assignment.start_date"></date>
            (<relative-time [iso]="assignment.start_date"></relative-time>)
          </dd>

          <dt *ngIf="assignment.end_date">End date:</dt>
          <dd *ngIf="assignment.end_date">
            <date [iso]="assignment.end_date"></date>
            (<relative-time [iso]="assignment.end_date"></relative-time>)
          </dd>
        </dl>
      </div>
      <div class="col-sm-6">
        <dl>
          <dt>Package:</dt>
          <dd *ngIf="assignment?.package?.id">{{ assignment.package.name }}</dd>
          <dd *ngIf="!assignment?.package?.id">
            <empty>No package selected</empty>
          </dd>

          <dt>Type:</dt>
          <dd>{{ assignment.type }}</dd>
        </dl>
      </div>
    </div>

    <section class="notes" *ngIf="assignment.notes" outline>
      <h2>Notes</h2>
      <p class="show-trimmed" data-limit="300" style="white-space: pre-line">{{ assignment.notes }}</p>
    </section>
  </div>
</section>
