import { Component, Input, OnChanges } from '@angular/core';
import { LinkSpec, LinkGenerator } from '@k2/common/links/link-generator';

@Component({
    selector: 'todo',
    templateUrl: 'todo.component.html',
    styleUrls: ['todo.component.scss'],
    standalone: false
})
export class TodoComponent implements OnChanges {
  @Input() todo: Todo;
  @Input() all: Todo[];
  routerLinkElements: string[];
  params = {};

  constructor(private generator: LinkGenerator) {}

  ngOnChanges() {
    if (this.todo) {
      const link = this.generator.generate(this.todo.link);
      const [url, rawQueryParams] = link.split('?');
      this.routerLinkElements = url.split('/');
      this.params = Array.from(new URLSearchParams(rawQueryParams).entries()).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: value }),
        {}
      );
    }
  }

  isPrevTodoComplete(currentTodo: Todo): boolean {
    const currentTodoIndex = this.all.findIndex(todo => todo.text === currentTodo.text);
    return currentTodoIndex === 0 || (currentTodoIndex > 0 && this.all.slice(0, currentTodoIndex).every(item => item.status === 'completed'));
  }
}

export interface Todo {
  readonly status: 'pending' | 'completed';
  readonly text: string;
  readonly link?: LinkSpec;
}
