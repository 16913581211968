import { Pipe, PipeTransform } from '@angular/core';
import { convertUnit, MeasurementsSystem } from '@k2/common/measurements';

@Pipe({
    name: 'unit',
    standalone: false
})
export class UnitPipe implements PipeTransform {
  transform(value: number, unit: string, system: MeasurementsSystem): string {
    return `${value} ${convertUnit(unit, system)}`;
  }
}
