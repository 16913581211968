import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { generateBackgroundStyle } from '../auth/backgrounds';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { requiredValidator } from '@k2/common/k2-forms/validators';
import { SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { AddFormError } from '@k2/common/k2-forms-state/actions';
import { ActionDispatcher } from '@k2/common/state/services/action-dispatcher';
import { ActivatedRoute, Router } from '@angular/router';
import { isNotNil, Subscriptions } from '@k2/common/helpers';
import { Backend } from '@k2/common/backend/backend';
import { catchError, first } from 'rxjs/operators';
import { AgreeTermsStatus } from '@k2/common/agree-terms/types';
import { of } from 'rxjs';

@Component({
    templateUrl: 'agree-terms.component.html',
    styleUrls: ['agree-terms.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class AgreeTermsComponent implements AfterViewInit, OnDestroy {
  readonly containerStyle = generateBackgroundStyle();
  protected form: UntypedFormGroup;
  agreementHash: string;
  k2_employee: {
    job_title: string,
    first_name: string,
    last_name: string
  } = {
    job_title: 'Compliance Manager',
    first_name: 'Katherine',
    last_name: 'Perez'
  };
  withdrawConsentEmailAddress: string = 'katherine.perez@k2corporatemobility.com';
  subscriptions: Subscriptions = new Subscriptions();
  agreementStatus: AgreeTermsStatus;
  showForm: boolean = true;
  showSuccessMessage: boolean = false;

  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;
  @ViewChild('card') contentCard: ElementRef;

  constructor(
    private actions: ActionDispatcher,
    private activatedRoute: ActivatedRoute,
    private backend: Backend,
    private router: Router
  ) {
    const { agreementHash } = this.activatedRoute.snapshot.params;
    this.agreementHash = agreementHash;

    this.subscriptions.add(
      this.backend.agreeTerms.fetchAgreeTermsStatus(agreementHash)
        .pipe(
          first(isNotNil),
          catchError(() => {
            this.showForm = false;
            return of(null);
          })
        )
        .subscribe(data => {
          if (data) {
            this.agreementStatus = data;
            this.showForm = this.agreementStatus.privacyPolicyStatus === 'ACTIVE';
          }
        })
    );

    this.form = new UntypedFormGroup({
      accept: new UntypedFormControl(false, [requiredValidator]),
      signature: new UntypedFormControl('', [requiredValidator])
    });
  }

  ngAfterViewInit() {
    this.resizeCanvas();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resizeCanvas();
  }

  resizeCanvas() {
    const canvas = document.querySelector('canvas');
    const widthCanvas = `${this.contentCard.nativeElement.clientWidth * 0.8}px`;
    const heightCanvas = `${this.contentCard.nativeElement.clientWidth * 0.2}px`;
    canvas.setAttribute('width', widthCanvas);
    canvas.setAttribute('height', heightCanvas);
    this.signaturePad.fromData(this.signaturePad.toData());
  }

  drawComplete(event: MouseEvent | Touch) {
    this.form.controls['signature'].setValue(this.signaturePad.toDataURL());
  }

  clearPad() {
    this.signaturePad.clear();
  }

  submit() {
    if (this.form.invalid) {
      this.actions.dispatch(new AddFormError());
      return;
    }
    this.subscriptions.add(
      this.backend.agreeTerms.updateAgreeTermsStatus(this.agreementHash, {}).subscribe(() =>
        this.backend.agreeTerms.generateAgreeTermsPdf({
          assignmentsId: this.agreementStatus.assignmentsId,
          assigneeName: this.agreementStatus.userName,
          signature: this.signaturePad.toDataURL(),
          k2Info: {
            officeAddress: this.agreementStatus.k2InvoicingOffice,
            employee: {
              name: 'Katherine Perez',
              position: 'Compliance Manager'
            }
          },
          date: new Date().toDateString()
        }).subscribe(() => {
            this.showSuccessMessage = true;
          }
        )
      )
    );
  }

  get year(): number {
    return new Date().getFullYear();
  }
}
