import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';

const spinnerDelay = 500;

@Component({
    selector: 'btn',
    templateUrl: 'btn.component.html',
    styleUrls: ['btn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BtnComponent implements OnChanges {
  @Input() type: 'primary' | 'success' | 'danger' = 'primary';
  @Input() htmlType: 'button' | 'submit' | 'reset' = 'button';
  @Input() icon?: string;
  @Input() link?: any;
  @Input() queryParams?: Record<string, string>;
  @Input() href?: string;
  @Input() disabled = false;
  @Output() click = new EventEmitter();
  @Input() id?: string;

  showSpinner = false;
  private _loading = false;
  private loadingTimeout: ReturnType<typeof setTimeout>;

  constructor(private cd: ChangeDetectorRef) {}

  @Input()
  set loading(newValue: boolean) {
    if (newValue === true && this._loading === false) {
      this.loadingTimeout = setTimeout(() => {
        this.showSpinner = true;
        this.cd.markForCheck();
      }, spinnerDelay);
    } else if (newValue === false) {
      clearTimeout(this.loadingTimeout);
      this.showSpinner = false;
    }
    this._loading = newValue;
    this.cd.markForCheck();
  }

  get showIcon(): boolean {
    return this.icon != null && this.showSpinner === false;
  }

  get isDisabled(): boolean {
    return this.disabled || this._loading;
  }

  get isButton(): boolean {
    return this.link == null && this.href == null;
  }

  handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    this.click.emit();
  };

  ngOnChanges(): void {
    if (this.link != null && this.href != null) {
      throw new Error(
        '<btn> component cannot have a "link" and "href" property at the same time! ' +
          'Please remove one of them.'
      );
    }
  }
}
