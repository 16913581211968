import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isString } from '@k2/common/helpers';
import { RefType } from '@k2/common/entities-state/types';
import { ClassNamePipe } from '@k2/common/ui/pipes/class-name.pipe';

const readable = {
  FIRSTLOGIN: 'First login'
};

@Component({
    selector: 'tag',
    templateUrl: 'tag.component.html',
    styleUrls: ['tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TagComponent {
  @Input() value: string | RefType;
  @Input() tooltip?: string;

  /**
   * Custom colors.
   */
  @Input() colors?: TagColors = {};

  /**
   * Sets the color explicitly. `value` and `colors` will be ignored.
   */
  @Input() color?: TagColor;

  constructor(private classNamePipe: ClassNamePipe) {}

  get normalizedValue(): string {
    if (this.value == null) return;
    if (isString(this.value)) return this.value as string;

    return (this.value as RefType).type_label;
  }

  get readableValue(): string {
    const normalized = this.normalizedValue;
    return readable[normalized] || normalized;
  }

  get className(): string {
    if (this.color) return `color-${this.color}`;
    const className = this.classNamePipe.transform(this.normalizedValue);

    const customColor = this.colors[className] || this.colors[this.normalizedValue];
    if (customColor != null) return `color-${customColor}`;

    return className;
  }
}

/**
 * A map of values and their colors.
 */
export type TagColors = Record<string, TagColor>;

export type TagColor = 'grey' | 'orange' | 'green' | 'red';
