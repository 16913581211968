import { Component, EventEmitter, Input } from '@angular/core';
import { Region } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { WithFormControl } from '@k2/common/k2-forms/field-control/utils';

@Component({
    selector: 'regions-control',
    templateUrl: 'regions-control.component.html',
    standalone: false
})
export class RegionsControlComponent extends WithFormControl<number[]> {
  @Input() field: Field<number[]>;
  @Input() changeServiceToUniversal: EventEmitter<any>;

  get regions(): Region[] {
    return this.field.attributes.valueOptions;
  }
}
