import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'notification-badge',
    templateUrl: 'notification-badge.component.html',
    styleUrls: ['notification-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NotificationBadgeComponent {
  @Input() count: number;
  @Input() important = false;
}
