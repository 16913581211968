import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Use this component as a link to the external untrusted website.
 */
@Component({
    selector: 'untrusted-link',
    templateUrl: 'untrusted-link.component.html',
    styleUrls: ['untrusted-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UntrustedLinkComponent {
  @Input() href: any;
}
