import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'no-file-msg',
    templateUrl: 'no-file-msg.component.html',
    styleUrls: ['no-file-msg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NoFileMsgComponent {}
