import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges, OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Field } from '@k2/common/k2-forms-state/types';
import { SelfSubmittingField } from '@k2/common/k2-forms/self-submitting-field/self-submitting-field';

@Component({
    selector: 'self-submitting-field',
    templateUrl: 'self-submitting-field.component.html',
    styleUrls: ['self-submitting-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SelfSubmittingFieldComponent extends SelfSubmittingField implements OnChanges {
  @Input() field: Field;
  @Input() class = '';
  @Input() loading?: boolean = false;
  @Output() savedValue = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<null>();

  ngOnChanges(changes: SimpleChanges): void {
    this.form = new UntypedFormGroup({
      [this.field.name]: this.field.control
    });
  }

  get controlSize(): 'small' | 'medium' | 'normal' {
    if (this.class.includes('small')) return 'small';
    if (this.class.includes('medium')) return 'medium';
    return 'normal';
  }

  protected get defaultValue() {
    return { [this.field.name]: this.field.defaultValue };
  }

  protected get submitValue() {
    this.savedValue.emit(true);
    return this.form.value;
  }

  checkUnsavedField(isNotSaved: boolean): void {
    this.savedValue.emit(!isNotSaved);
  }

  saveClicked() {
    this.onSave.emit();
  }
}
