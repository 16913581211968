import { Pipe, PipeTransform } from '@angular/core';

const suffixes = ['th', 'st', 'nd', 'rd'];

@Pipe({
    name: 'dateSuffix',
    standalone: false
})
export class DateSuffixPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null) return;

    const relevantDigits = value < 30 ? value % 20 : value % 30;
    const suffix = relevantDigits <= 3 ? suffixes[relevantDigits] : suffixes[0];

    return value + suffix;
  }
}
