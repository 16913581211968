import { Component, Input } from '@angular/core';

@Component({
    selector: 'feedback-responses',
    templateUrl: 'feedback-responses.component.html',
    styleUrls: ['feedback-responses.component.scss'],
    standalone: false
})
export class FeedbackResponsesComponent {
  @Input() responses: Array<{ question: string; response: string }>;
}
