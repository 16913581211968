import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@k2/common/entities-state/types';

@Component({
    selector: 'user-ingot',
    templateUrl: 'user-ingot.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UserIngotComponent {
  @Input() user: User;
  @Input() link: any;
}
