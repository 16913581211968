import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { calculatePasswordStrength } from '@k2/common/ui/components/password-strength/calculator';
import { range } from 'ramda';

const levels = 5;

@Component({
    selector: 'password-strength',
    templateUrl: 'password-strength.component.html',
    styleUrls: ['password-strength.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PasswordStrengthComponent {
  @Input() password: string;

  get levelsAsRange(): number[] {
    return range(0, levels);
  }

  get visible(): boolean {
    return this.password != null && this.password.length > 0;
  }

  get strength(): number {
    return normalizeStrength(calculatePasswordStrength(this.password || ''));
  }
}

function normalizeStrength(strength: number): number {
  const step = 100 / levels;
  const result = Math.round(strength / step);
  return result === 0 ? 0 : result - 1;
}
