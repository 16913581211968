import { Component, Input, OnChanges } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Field } from '@k2/common/k2-forms-state/types';
import { addValidators, WithFormControl } from '@k2/common/k2-forms/field-control/utils';
import moment from 'moment';

@Component({
    selector: 'date-control',
    templateUrl: 'date-control.component.html',
    standalone: false
})
export class DateControlComponent extends WithFormControl<string> implements OnChanges {

  @Input() field: Field<string>;

  private interimValue: moment.Moment;
  momentValue: string;

  ngOnChanges(): void {
    addValidators(this.field.control, this.customValidator);
    if (!!this.interimValue && this.interimValue.unix() * 1000 === Date.parse(this.value)) {
      this.momentValue = this.interimValue.toISOString();
    }
    this.momentValue = this.value;
  }

  private customValidator = (_: AbstractControl): ValidationErrors => {
    if (this.min && this.min.isAfter(this.momentValue)) {
      return { futureOnlyDate: 'Date needs to be in the future' };
    }
    if (this.max && this.max.isBefore(this.momentValue)) {
      return { pastOnlyDate: 'Date needs to be in the past' };
    }
    return null;
  };

  get startView(): 'month' | 'year' {
    switch (this.field.attributes.default_view) {
      case 'month':
      case 'year':
        return this.field.attributes.default_view;
      default:
        return 'month';
    }
  }

  onValueChange(value: moment.Moment) {
    this.momentValue = value?.toISOString();
    this.interimValue = moment.utc(this.momentValue);
    this.value = this.momentValue ? this.momentValue : null;
  }

  get min() {
    const rule = this.dateValidatorRule;
    if (rule && rule.params && rule.params.future_only) {
      if (rule.params.addDays) {
        return moment.utc().add(rule.params.addDays, 'day').startOf('day');
      }
      return moment.utc().startOf('day');
    }
  }

  get max() {
    const rule = this.dateValidatorRule;
    if (rule && rule.params && rule.params.past_only) return moment.utc().startOf('day');
  }

  private get dateValidatorRule() {
    const validators = this.field.validators || [];
    return validators.find(validator => validator.rule === 'IsDate');
  }

  setValue(date: string) {
    this.momentValue = date;
    this.interimValue = moment.utc(this.momentValue);
    if (this.momentValue === null) {
      this.value = null;
    } else {
      this.value = this.momentValue;
    }
  }
}
