<mat-select
  #select
  [id]="matSelectId"
  [(value)]="value"
  [disabled]="disabled"
  [placeholder]="placeholder"
  (selectionChange)="changeValue()"
  (openedChange)="openedChange.emit($event)"
  [class.black-border]="select.focused"
  class="form-control"
  [class.disabled]="disabled"
  [multiple]="multiple"
>
  <mat-option *ngIf="!multiple">
    <ngx-mat-select-search
      placeholderLabel=""
      noEntriesFoundLabel="Nothing found"
      [hideClearSearchButton]="true"
      [formControl]="filterCtrl"
    ></ngx-mat-select-search>
  </mat-option>

  <mat-option
    *ngFor="let option of filteredOptions; let i = index"
    [value]="option.id"
    [class.hidden]="!option.text"
    id="{{('option-' + i)}}"
  >
    {{ option.text }}
    <span *ngIf="option.has_any_claims" matTooltipClass="primary-tooltip" matTooltipPosition="above" [matTooltip]="'Previous insurance claim recorded'">
      <img src="broken_glass.png" class="broken-glass" style="  height: fit-content; width: 25px;"/>
    </span>

  </mat-option>
</mat-select>
