import { Component, Input, OnChanges } from '@angular/core';
import { Partner } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { PartnersSelectorConfig } from '@k2/common/partners/component/partners-selector/partners-selector.component';
import { isEmpty, isNil } from 'ramda';

@Component({
    selector: 'partners-selector-control',
    templateUrl: 'partners-selector-control.component.html',
    standalone: false
})
export class PartnersSelectorControlComponent implements OnChanges {
  @Input() field: Field<Partner | Partner[]>;

  selected: Partner[];
  config: PartnersSelectorConfig;

  ngOnChanges(): void {
    const value: any = this.field.defaultValue;
    const multiple = Array.isArray(value);

    this.config = {
      multiple,
      placeholder: this.field.attributes.placeholder
    };

    if (multiple) {
      this.selected = value;
    } else {
      this.selected = isEmpty(value) || isNil(value) ? [] : [value];
    }
  }

  update = (selected: Partner[]) => {
    this.selected = selected;

    if (this.config.multiple) {
      this.field.control.patchValue(selected);
    } else {
      this.field.control.patchValue(selected[0] || null);
    }
  };
}
