import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'days',
    standalone: false
})
export class DaysPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) return;

    if (value > 0 && value <= 0.5) return 'half day';
    if (value === 1.0) return 'all day';
    if (value > 1.0) return value.toFixed(1) + ' days';
    if (value === 0) return 'zero days';
  }
}
