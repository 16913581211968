import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'collapsible-block',
    templateUrl: 'collapsible-block.component.html',
    styleUrls: ['collapsible-block.component.scss'],
    standalone: false
})
export class CollapsibleBlockComponent {
  @Input() collapsed: boolean;

  @Output() collapsedChange = new EventEmitter<boolean>();

  changeCollapsed(collapsed: boolean) {
    this.collapsed = collapsed;
    this.collapsedChange.emit(this.collapsed);
  }
}
