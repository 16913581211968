import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userName',
    standalone: false
})
export class UserNamePipe implements PipeTransform {
  transform(user: User) {
    return toUserName(user);
  }
}

export function toUserName(user: User): string | undefined {
  if (user == null || (user.first_name == null && user.last_name == null)) return;
  return `${user.first_name || ''} ${user.last_name || ''}`.trim();
}

interface User {
  readonly first_name: string;
  readonly last_name: string;
}
