import { Component, Input } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';

@Component({
    selector: 'email-control',
    templateUrl: 'email-control.component.html',
    standalone: false
})
export class EmailControlComponent {
  @Input() field: Field<string>;
}
