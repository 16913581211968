import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UpdateActivity } from '@k2/common/entities-state/types';

@Component({
    selector: 'last-updated',
    templateUrl: 'last-updated.component.html',
    styleUrls: ['last-updated.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LastUpdatedComponent {
  @Input() activity: UpdateActivity;
}
