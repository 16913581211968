import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'text-filter',
    templateUrl: 'text-filter.component.html',
    styleUrls: ['text-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TextFilterComponent {
  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() type: string = 'text';
}
