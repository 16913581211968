import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkedAssignment } from '@k2/common/entities-state/types';

@Component({
    selector: 'assignment-ingot',
    templateUrl: 'assignment-ingot.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssignmentIngotComponent {
  @Input() linkedAssignment: LinkedAssignment;
}
