import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@k2/common/entities-state/types';

@Component({
    selector: 'address-block',
    templateUrl: 'address-block.component.html',
    styleUrls: ['address-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddressBlockComponent {
  @Input() address: Address;
  @Input() showStatus = false;
  @Input() reducedFormat?: boolean = false;
  @Input() isAssignmentEncrypted: boolean = false;
}
