import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Platform } from '@angular/cdk/platform';
import { TooltipComponent } from '@k2/common/ui/components/tooltip/tooltip.component';

@Directive({
    selector: '[customTooltip]',
    standalone: false
})
export class TooltipDirective implements OnInit, OnDestroy {

  @Input() text = '';
  @Input() listData: { title: string, value: string }[];

  private overlayRef: OverlayRef;

  timeout: any;

  constructor(private overlay: Overlay,
              private elementRef: ElementRef,
              private platform: Platform) {
  }

  ngOnInit() {
    this.overlayRef = this.overlay.create({});
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
    this.overlayRef.detach();
    this.overlayRef.dispose();
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event) {
    if (event.target.className.includes('mat-autocomplete-trigger')) {
      this.overlayRef.detach();
    }
  }

  @HostListener('mouseover')
  omMouseOver() {
    if (!(this.platform.ANDROID || this.platform.IOS)) {
      this.show();
    }
  }

  @HostListener('touchstart')
  onTouchStart() {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.timeout = setTimeout(() => {
        this.show();
      }, 500);
    }
  }

  show() {
    if (this.text || this.listData?.length) {
      // Create tooltip portal
      const tooltipPortal = new ComponentPortal(TooltipComponent);

      // Attach tooltip portal to overlay
      if (!this.overlayRef.hasAttached()) {
        const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(tooltipPortal);

        // Pass content to tooltip component instance
        tooltipRef.instance.text = this.text;
        tooltipRef.instance.listData = this.listData;
        tooltipRef.instance.config = this.elementRef.nativeElement;
      }
    }
  }

  @HostListener('document:keyup.escape')
  @HostListener('mouseleave')
  @HostListener('touchend')
  mouseLeave() {
    clearTimeout(this.timeout);
    this.overlayRef.detach();
  }

  @HostListener('mouseup', ['$event'])
  mouseUp(event) {
    if (!event.target.className.includes('checkbox') && !event.target.offsetParent.className.includes('mat-option-multiple')) {
      this.overlayRef.detach();
    }
  }
}
