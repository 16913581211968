import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'back-forward-filter',
    templateUrl: 'back-forward-filter.component.html',
    styleUrls: ['back-forward-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BackForwardFilterComponent {
  @Input() control: UntypedFormControl;
  @Input() forwardLabel: string;
  @Input() backLabel: string;
  @Input() forwardValue: string;
  @Input() backValue: string;
  @Input() currentLabel: string;
  @Input() update;

  setBackValue() {
    this.control.patchValue(this.backValue);
    this.update();
  }

  setForwardValue() {
    this.control.patchValue(this.forwardValue);
    this.update();
  }
}
