import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@k2/common/entities-state/types';

@Component({
    selector: 'assignment-location',
    templateUrl: 'assignment-location.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssignmentLocationComponent {
  @Input() origin: Location;
  @Input() destination: Location;

  get hasLocations(): boolean {
    return this.origin != null && this.destination != null;
  }
}
