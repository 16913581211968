import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { loadAmMap } from '@k2/common/map/am-map';
import { planeSVG, targetSVG } from '@k2/common/map/svgs';
import {
  calculateZoomLatitude,
  calculateZoomLevel,
  calculateZoomLongitude,
  defaultMaxZoom
} from '@k2/common/map/zoom';

const planePositionScale = { min: 1.02, max: 1.8 };

@Component({
    selector: 'flight-map',
    templateUrl: 'flight-map.component.html',
    styleUrls: ['flight-map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FlightMapComponent implements OnChanges, OnDestroy {
  @Input() origin: FlightMapLocation;
  @Input() destination: FlightMapLocation;

  @ViewChild('mapWrapper', { static: true }) private readonly mapElement: ElementRef;

  private map;

  ngOnChanges(changes: SimpleChanges) {
    this.tryDestroyMap();
    this.createMap();
  }

  private createMap = async () => {
    const amMap = await loadAmMap();

    const mapElement = this.mapElement.nativeElement;
    const positions = [this.origin, this.destination];

    this.map = amMap.makeChart(mapElement, {
      type: 'map',
      theme: 'light',
      projection: 'miller',

      dataProvider: {
        map: 'worldLow',
        zoomLatitude: calculateZoomLatitude(positions),
        zoomLongitude: calculateZoomLongitude(positions),
        zoomLevel: calculateZoomLevel({ positions, zoomModifier: 0.55 }),
        lines: [
          {
            id: 'line',
            arc: -0.85,
            alpha: 0.3,
            latitudes: [this.origin.latitude, this.destination.latitude],
            longitudes: [this.origin.longitude, this.destination.longitude]
          }
        ],
        images: [
          toTargetImage(this.origin),
          toTargetImage(this.destination),
          {
            svgPath: planeSVG,
            positionOnLine: 0,
            color: '#585869',
            animateAlongLine: true,
            lineId: 'line',
            flipDirection: false,
            loop: true,
            scale: 0.03,
            positionScale: calculatePlanePositionScale({ positions })
          }
        ]
      },
      areasSettings: {
        unlistedAreasColor: '#000000',
        unlistedAreasAlpha: 0.1
      },
      imagesSettings: {
        color: '#585869',
        rollOverColor: '#585869',
        selectedColor: '#585869',
        pauseDuration: 0.5,
        animationDuration: 4,
        adjustAnimationSpeed: false
      },
      linesSettings: {
        color: '#585869',
        alpha: 0.4
      }
    });
  };

  ngOnDestroy() {
    this.tryDestroyMap();
  }

  private tryDestroyMap = () => {
    if (this.map) {
      this.map.clear();
    }
  };
}

function toTargetImage(location: FlightMapLocation) {
  return {
    svgPath: targetSVG,
    label: location.name,
    labelPosition: 'bottom',
    latitude: location.latitude,
    longitude: location.longitude
  };
}

function calculatePlanePositionScale(positions): number {
  const minScale = planePositionScale.min;
  const maxScale = planePositionScale.max;

  return maxScale - ((maxScale - minScale) / defaultMaxZoom) * calculateZoomLevel(positions);
}

export interface FlightMapLocation {
  name: string;
  latitude: number;
  longitude: number;
}
