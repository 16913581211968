import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
    selector: 'milestones',
    templateUrl: 'milestones.component.html',
    styleUrls: ['milestones.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MilestonesComponent implements OnInit {
  @Input() milestones: any[];
  @Input() initialValue;
  @Input() disabled: boolean;
  @Output() update = new EventEmitter();

  current;
  indexOfCurrent: number;
  currentLineStyle = {};
  currentPointStyle = {};

  ngOnInit(): void {
    this.setValues(this.initialValue);
  }

  setValues = (value: any) => {
    this.current = value;

    let indexOfCurrent = this.findIndex(value);
    this.currentLineStyle = this.toLineStyle(indexOfCurrent);
    this.currentPointStyle = this.toPointStyle(indexOfCurrent);
  };

  updateMilestoneStatus = (milestoneId: string) => {
    this.update.emit(milestoneId);
  };

  findIndex = (value: any): number => {
    return this.milestones.findIndex(option => option.value === value);
  };

  toPointStyle = (index: number): Object => {
    let size = this.milestones.length;

    switch (index) {
      case -1:
        return { display: 'none' };
      case 0:
        return { left: '0%' };
      case size - 1:
        return { left: 'calc(100% - 18px)' };
      default:
        let percentage = toPercentage(index, size);
        return { left: 'calc(' + percentage + '% - 3px)' };
    }
  };

  toLineStyle = (index: number): Object => {
    let size = this.milestones.length;

    switch (index) {
      case 0:
        return { width: '20px' };
      case size - 1:
        return { width: '100%' };
      default:
        let percentage = toPercentage(index, size);
        return { width: 'calc(' + percentage + '% + 3px)' };
    }
  };

  toLabelStyle = (index: number): Object => {
    let size = this.milestones.length;

    switch (index) {
      case 0:
        return { left: '-65px', 'text-align': 'center' };
      case size - 1:
        return { left: 'calc(100% - 85px)', 'text-align': 'center' };
      default:
        let percentage = toPercentage(index, size);
        return { left: 'calc(' + percentage + '% - 65px)', 'text-align': 'center' };
    }
  };

  areAllMilestonesActive = (): boolean => {
    return this.findIndex(this.current) === this.milestones.length - 1;
  };

  isActive = (milestone: { value: any }) => {
    let index = this.findIndex(milestone.value);
    let indexOfCurrent = this.findIndex(this.current);

    return index <= indexOfCurrent;
  };
}

function toPercentage(index: number, size: number): number {
  return (100 / (size - 1)) * index;
}
