import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'yes-no-label',
    templateUrl: 'yes-no-label.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class YesNoLabelComponent {
  @Input('condition') isTrue: boolean;

  get status(): string {
    return this.isTrue ? 'YES' : 'NO';
  }
}
