import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  isNotEmpty,
  isNotNil,
  isNotString,
  isString,
  pipeValuesTo,
  Subscriptions
} from '@k2/common/helpers';
import { includesParts } from '@k2/common/filters/utils';
import { ComponentSpec } from '@k2/common/ui/component-spec';
import { uniqBy } from 'ramda';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, pairwise, switchMap, withLatestFrom } from 'rxjs/operators';

const cacheSize = 30;
const visibleItemsLimit = 30;

@Component({
    selector: 'autocomplete-selector',
    templateUrl: 'autocomplete-selector.component.html',
    styleUrls: ['autocomplete-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AutocompleteSelectorComponent<T> implements OnChanges, OnDestroy {
  @Input() config: AutocompleteSelectorConfig<T>;
  @Input() selected: T[] = [];
  @Output() selectedChange = new EventEmitter<T[]>();

  readonly searchControl = new UntypedFormControl();
  readonly options: Observable<T[]>;
  private readonly optionsSubject = new BehaviorSubject<T[]>(null);
  private readonly subscriptions = new Subscriptions();

  constructor() {
    this.options = this.createOptionsStream();
    this.listenToSearchEvents();
    this.listenToSelectEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config != null) {
      this.optionsSubject.next(null);
    }

    if (this.config.disabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  private createOptionsStream = () => {
    return combineLatest([
      this.searchControl.valueChanges.pipe(filter(isString)),
      this.optionsSubject
    ]).pipe(
      map(([query, items]) => {
        if (items == null) return [];
        return items.filter(item => includesParts(this.toName(item), query));
      }),
      map(items => items.slice(0, visibleItemsLimit))
    );
  };

  private listenToSearchEvents = () => {
    this.subscriptions.add(
      this.searchControl.valueChanges
        .pipe(
          filter(isString),
          filter(isNotEmpty),
          filter(this.hasMinQueryLength),
          pairwise(),
          withLatestFrom(this.optionsSubject.pipe(map(items => (items ? items.length : Infinity)))),
          filter(([[previousQuery, query], optionsLength]) => {
            return !query.startsWith(previousQuery) || optionsLength > cacheSize;
          }),
          switchMap(([[, query]]) => this.config.query(query))
        )
        .subscribe(pipeValuesTo(this.optionsSubject))
    );
  };

  private listenToSelectEvents = () => {
    this.subscriptions.add(
      this.searchControl.valueChanges
        .pipe(filter(isNotNil), filter(isNotString))
        .subscribe((item: T) => {
          this.searchControl.patchValue(null);
          this.add(item);
        })
    );
  };

  add = (item: T) => {
    if (this.config.multiple) {
      const selected = uniqBy(this.config.toId, [...this.selected, item]);
      this.selectedChange.emit(selected);
    } else {
      this.selectedChange.emit([item]);
    }
  };

  remove = (item: T) => {
    const selected = this.selected.filter(selected => {
      return this.config.toId(selected) !== this.config.toId(item);
    });

    this.selectedChange.emit(selected);
  };

  toName = (item: T) => {
    if (item == null) return;
    return this.config.toName(item);
  };

  hasMinQueryLength = (query: string): boolean => {
    return !this.config.minQueryLength || query.length >= this.config.minQueryLength;
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export interface AutocompleteSelectorConfig<T> {
  readonly query: (query: string) => Observable<T[]>;
  readonly toName: (item: T) => string;
  readonly toId: (item: T) => number | string;
  readonly toSelectedSpec: (item: T) => ComponentSpec;
  readonly toOptionSpec: (item: T, query: string) => ComponentSpec;
  readonly multiple: boolean;
  readonly placeholder?: string;
  readonly disabled?: boolean;
  readonly minQueryLength?: number;
}
