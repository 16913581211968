import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'list-dialog',
    templateUrl: 'list-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ListDialogData) {}
}

export interface ListDialogData {
  title: string;
  items: string;
}
