import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StorageUsage } from '@k2/common/entities-state/types';

@Component({
    selector: 'storage-usage',
    templateUrl: 'storage-usage.component.html',
    styleUrls: ['storage-usage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StorageUsageComponent {
  @Input() usage: StorageUsage;

  get assignments() {
    return this.usage ? this.usage.assignments : 0;
  }

  get containers() {
    return this.usage ? this.usage.containers : 0;
  }

  get volume() {
    return this.usage ? this.usage.volume : 0;
  }
}
