import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscriptions } from '@k2/common/helpers';
import { ServiceIncludedAs } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { fromPairs, head, mergeDeepLeft, values } from 'ramda';
import { startWith } from 'rxjs/operators';

@Component({
    selector: 'workflow-service-variations-control',
    templateUrl: 'workflow-service-variations-control.component.html',
    styleUrls: ['workflow-service-variations-control.component.scss'],
    standalone: false
})
export class WorkflowServiceVariationsControlComponent implements OnChanges, OnDestroy {
  @Input() field: Field<Value>;
  value: Value;
  private readonly subscriptions = new Subscriptions();

  ngOnChanges(changes: SimpleChanges): void {
    this.subscriptions.add(
      this.field.control.valueChanges.pipe(startWith(this.field.control.value)).subscribe(value => {
        const defaultModels = this.workflows
          .reduce((services, workflow) => [...services, ...workflow.services], [])
          .map(service => {
            return {
              id: service.id,
              variation: service.variations == null ? null : head(values(service.variations)).id,
              package_status: 'DISABLED'
            };
          });

        const defaultValue = fromPairs(defaultModels.map(model => [model.id, model]) as any[]);
        this.value = mergeDeepLeft(value, defaultValue);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get workflows(): WorkflowOption[] {
    return this.field.attributes.valueOptions.filter(workflows => workflows.services.length > 0);
  }

  propagateValueChange() {
    this.field.control.setValue(this.value);
  }

  get disabled() {
    return this.field.control.disabled;
  }

  touch = () => {
    this.field.control.markAsTouched();
  };
}

interface Value {
  [serviceId: number]: OptionModel;
}

interface OptionModel {
  id: number;
  variation: number | null;
  package_status: ServiceIncludedAs;
}

interface WorkflowOption {
  id: number;
  label: string;
  services: Service[];
}

interface Service {
  id: number;
  name: string;
  variations: Variation[];
}

interface Variation {
  id: number;
  value: string;
  label: string;
}
