import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MapDialogComponent } from '@k2/common/dialogs/components/map/map-dialog.component';
import { PartnerFeedback, PartnerFeedbackHistoryRecord } from '@k2/common/entities-state/types';
import { Paginator } from '@k2/common/pagination/paginator';
import { fromPairs, pair, sum, values } from 'ramda';

@Component({
    selector: 'partner-feedback',
    templateUrl: 'partner-feedback.component.html',
    styleUrls: ['partner-feedback.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PartnerFeedbackComponent {
  @Input() feedback: PartnerFeedback;
  paginator = Paginator.table();

  constructor(private dialog: MatDialog) {}

  get historyRecords(): PartnerFeedbackHistoryRecord[] {
    return this.feedback.history.rows;
  }

  get ratingsCount(): number {
    return sum(values(this.feedback.rating_breakdown));
  }

  openResponsesDialog = (record: PartnerFeedbackHistoryRecord) => {
    const map = fromPairs(record.responses.map(r => pair(r.question, r.response)));

    this.dialog.open(MapDialogComponent, {
      data: { title: 'Feedback responses', map }
    });
  };
}
