import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'ramda';

@Pipe({
    name: 'isNotEmpty',
    standalone: false
})
export class IsNotEmptyPipe implements PipeTransform {
  transform(value: any): boolean {
    return value != null && !isEmpty(value);
  }
}
