import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { AppState } from '@k2/app-state';
import { appConfig } from '@k2/common/app-config';
import { getRealSession } from '@k2/common/sessions-state/reducers';
import { AppStore } from '@k2/common/state/services/app-store';
import { afterAppViewChecked } from 'src/bootstrap';

const { fullstoryAccountId } = appConfig;

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    standalone: false
})
export class AppComponent implements OnInit, AfterViewChecked {
  constructor(private store: AppStore<AppState>) {}

  ngOnInit() {
    if (fullstoryAccountId != null) {
      import('./common/fullstory').then(({ bootstrapFullstory }) =>
        bootstrapFullstory(fullstoryAccountId, this.store.select(getRealSession))
      );
    }
  }

  ngAfterViewChecked() {
    afterAppViewChecked();
  }
}
