import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { K2SelectOption } from '@k2/common/k2-select/k2-select-option';
import { UntypedFormControl } from '@angular/forms';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'k2-select',
    templateUrl: 'k2-select.component.html',
    styleUrls: ['k2-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class K2SelectComponent implements OnChanges, OnDestroy {
  @Input() value: any;
  @Input() options: K2SelectOption[];
  @Input() placeholder = 'Please select...';
  @Input() disabled = false;
  @Input() multiple = false;
  @Input() matSelectId?: string;

  @Output() valueChange = new EventEmitter<any>();
  @Output() openedChange = new EventEmitter<boolean>();
  filterCtrl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: K2SelectOption[];
  alive = false;

  constructor() {
    this.alive = true;
    this.filterCtrl.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(val => {
      if (val) {
        this.filteredOptions = this.options.filter(({ text }) =>
          !text || text.toLowerCase().includes(val.toLowerCase())
        );
      } else {
        this.filteredOptions = this.options;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.options &&
      (!changes.options.previousValue ||
        changes.options.previousValue.length !== changes.options.currentValue.length)
    ) {
      this.filteredOptions = this.options;
    }
  }

  changeValue() {
    this.filterCtrl.patchValue(null);
    this.valueChange.emit(this.value);
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
