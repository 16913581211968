import { Component, Input } from '@angular/core';
import { Field, ValueOption } from '@k2/common/k2-forms-state/types';
import { WithFormControl } from '@k2/common/k2-forms/field-control/utils';

@Component({
    selector: 'radiobar-control',
    templateUrl: 'radiobar-control.component.html',
    styleUrls: ['radiobar-control.component.scss'],
    standalone: false
})
export class RadiobarControlComponent extends WithFormControl {
  @Input() field: Field<string | number>;

  get options(): ValueOption[] {
    return this.field.attributes.valueOptions;
  }
}
