import { Component, OnChanges, Input } from '@angular/core';
import { Assignment } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { isEmpty, isNil } from 'ramda';
import { assignmentSelectorConfig } from '@k2/common/assignments/components/assignment-selector/assignment-selector.component';

@Component({
    selector: 'k2-links-selector-control',
    templateUrl: 'k2-links-selector-control.component.html',
    standalone: false
})
export class K2LinksSelectorControlComponent implements OnChanges {
  @Input() field: Field<Assignment>;

  selected: Assignment;
  config: assignmentSelectorConfig;

  ngOnChanges() {
    const value: any = this.field.defaultValue;

    this.config = {
      multiple: false,
      excluded: this.field.attributes.excluded || [],
      placeholder: this.field.attributes.placeholder
    };

    this.selected = isEmpty(value) || isNil(value) ? null : value;
  }

  update = (selected: Assignment) => {
    this.selected = selected;
    this.field.control.patchValue(selected[0] || null);
  };
}
