import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'className',
    standalone: false
})
export class ClassNamePipe implements PipeTransform {
  transform(value: string): string {
    if (value == null) return value;

    return value
      .toString()
      .replace(/\(|\)/g, '')
      .replace(/[^a-zA-Z0-9-]/g, '-')
      .toLowerCase();
  }
}
