import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CustomField } from '@k2/common/entities-state/types';

@Component({
    selector: 'custom-fields-review',
    templateUrl: 'custom-fields-review.component.html',
    styleUrls: ['custom-fields-review.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomFieldsReviewComponent {
  @Input() fields: CustomField[];
}
