import { Component, Input } from '@angular/core';

/**
 * Bordered card with label.
 */
@Component({
    selector: 'card',
    templateUrl: 'card.component.html',
    styleUrls: ['card.component.scss'],
    standalone: false
})
export class CardComponent {
  @Input() label?: string;
}
