import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { User } from '@k2/common/entities-state/types';
import { Router } from '@angular/router';

@Component({
    selector: 'text-dialog',
    templateUrl: 'text-dialog.component.html',
    styleUrls: ['text-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TextDialogComponent implements OnInit {
  redirectId = null;
  confirmCheckbox: boolean = false;

  constructor(public dialogRef: MatDialogRef<TextDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TextDialogData,
              private router: Router) {}

  ngOnInit() {
    this.dialogRef.afterClosed().subscribe(res => {
      if (res === 'submit' && this.data.onSubmit) {
        this.data.onSubmit();
      } else if (res === 'cancel' && this.data.onCancel) {
        this.data.onCancel();
      } else if (this.redirectId) {
        this.router.navigateByUrl(`/staff/k2-users/teams/users/${this.redirectId}`);
      }
    });
  }

  onLinkClick(id: number) {
    this.redirectId = id;
    this.dialogRef.close();
  }
}

export interface TextDialogData {
  title?: string;
  text: string;
  subText?: string;
  centeredTitle?: boolean;
  centeredText?: boolean;
  centeredBtns?: boolean;
  checkboxText?: string;
  cancelBtnText?: string;
  submitBtnText?: string;
  onSubmit?: () => void;
  usersToRedirect?: User[];
  onCancel?: () => void;
}
