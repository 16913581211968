import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Assignee } from '@k2/common/entities-state/types';

@Component({
    selector: 'assignee-family-card',
    templateUrl: 'assignee-family-card.component.html',
    styleUrls: ['assignee-family-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssigneeFamilyCardComponent {
  @Input() assignee: Assignee;
  @Input() assigneeLink: any;
  @Input() familyLink: any;
  @Input() petsLink: any;
  @Input() isAssignmentEncrypted: boolean = true;
}
