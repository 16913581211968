import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { ComponentSpec } from '../../component-spec';
import { AutocompleteSelectorConfig } from '../autocomplete-selector/autocomplete-selector.component';
import { Airport } from './airport';
import { AirportAutocompleteOptionComponent } from './airport-autocomplete-option.component';
import { AirportIngotComponent } from './airport-ingot.component';

@Component({
    selector: 'airport-selector',
    templateUrl: 'airport-selector.component.html',
    styleUrls: ['airport-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AirportSelectorComponent {
  @Input() selected: Airport[];
  @Output() selectedChange = new EventEmitter<Airport[]>();

  selectorConfig: AutocompleteSelectorConfig<Airport>;

  constructor(private backend: Backend) {
    this.selectorConfig = {
      toId: (airport: Airport) => airport.iata_code,
      toName: (airport: Airport) => airport.name + ' (' + airport.iata_code + ')',
      query: (query: string) => {
        return this.backend.search.searchAirports(query);
      },
      toSelectedSpec: (airport: Airport): ComponentSpec<AirportIngotComponent> => ({
        component: AirportIngotComponent,
        inputs: { airport }
      }),
      toOptionSpec: (
        airport: Airport,
        query: string
      ): ComponentSpec<Partial<AirportAutocompleteOptionComponent>> => ({
        component: AirportAutocompleteOptionComponent,
        inputs: { airport, highlight: query }
      }),
      multiple: false,
      placeholder: 'Search airport',
      minQueryLength: 3
    };
  }

  update = (selected: Airport[]) => {
    this.selectedChange.emit(selected);
  };
}
