import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'array-field-controls',
    templateUrl: 'array-field-controls.component.html',
    styleUrls: ['array-field-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ArrayFieldControlsComponent {
  @Input() canRemove: boolean;
  @Input() canAdd: boolean;
  @Output() remove = new EventEmitter();
  @Output() add = new EventEmitter();
}
