import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  Type,
  ViewContainerRef
} from '@angular/core';
import { safe } from '@k2/common/helpers';
import { Todo } from '@k2/common/ui/components/todo/todo.component';
import { FailedComponent } from '@k2/common/ui/directives/subscribe/state-messages/failed/failed.component';
import { LoadingComponent } from '@k2/common/ui/directives/subscribe/state-messages/loading/loading.component';
import { PreparingComponent } from '@k2/common/ui/directives/subscribe/state-messages/preparing/preparing.component';
import { TodosComponent } from '@k2/common/ui/directives/subscribe/state-messages/todos/todos.component';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

const loadingDelay = 300;

export const ngSubscribeStateComponents = [
  FailedComponent,
  LoadingComponent,
  PreparingComponent,
  TodosComponent
];

/**
 * Subscribes to the given observable and potentially shows a loading/error/todo message.
 *
 * Resolved value will be passed into the inner template.
 *
 * EXAMPLE:
 *   <div *ngSubscribe="values$ as value">
 *     {{ value | json}}
 *   </div>
 */
@Directive({
    selector: '[ngSubscribe]',
    standalone: false
})
export class NgSubscribeDirective implements OnDestroy {
  private observable: Observable<any>;
  private context: Context;
  private subscription: Subscription;
  private loadingTimer;

  constructor(
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private templateRef: TemplateRef<any>,
  ) {
  }

  @Input()
  set ngSubscribe(inputObservable: Observable<any>) {
    if (this.observable === inputObservable) return;

    this.observable = inputObservable;
    this.renderComponent(PreparingComponent);
    this.scheduleLoadingScreen();

    this.subscribe();
  }

  private subscribe = () => {
    this.context = null;
    this.unsubscribe();

    this.subscription = this.observable.pipe(distinctUntilChanged()).subscribe(
      value => {
        if (value === undefined) {
          this.context = null;
          this.scheduleLoadingScreen();
        } else {
          if (this.context == null) {
            this.abortLoadingScreen();
            this.context = new Context();
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef, this.context);
          }

          this.context.ngSubscribe = value;
          this.cdr.markForCheck();
        }
      },
      error => {
        this.abortLoadingScreen();
        const todos = extractTodos(error);

        if (todos.length === 0) {
          console.error(error);
          this.renderComponent(FailedComponent);
        } else {
          this.renderComponent(TodosComponent, { todos });
        }
      }
    );
  };

  private unsubscribe = () => {
    if (this.subscription) this.subscription.unsubscribe();
  };

  ngOnDestroy = this.unsubscribe;

  private renderComponent = <T extends object>(component: Type<T>, inputs?: Pick<T, keyof T>) => {
    this.viewContainer.clear();

    const componentRef = this.viewContainer.createComponent(component);

    if (inputs) Object.assign(componentRef.instance, inputs);
    this.cdr.markForCheck();
  };

  private scheduleLoadingScreen = () => {
    if (this.loadingTimer) return;

    this.loadingTimer = setTimeout(() => {
      this.renderComponent(LoadingComponent);
      this.loadingTimer = undefined;
    }, loadingDelay);
  };

  private abortLoadingScreen = () => {
    clearTimeout(this.loadingTimer);
    this.loadingTimer = undefined;
  };
}

function extractTodos(error: any): Todo[] {
  return safe(() => error.error.payload.todos) || [];
}

class Context {
  ngSubscribe = null;
}
