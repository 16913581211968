import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@k2/common/entities-state/types';

@Component({
    selector: 'location-autocomplete-option',
    templateUrl: 'location-autocomplete-option.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class LocationAutocompleteOptionComponent {
  @Input() location: Location;
  @Input() highlight: string;
}
