import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { groupBy } from 'ramda';

const noGroupName = '_NO_GROUP';

@Component({
    selector: 'basic-select',
    templateUrl: 'basic-select.component.html',
    styleUrls: ['basic-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BasicSelectComponent<T extends number | string> implements OnChanges {
  @Input() id?: string | number;
  @Input() value?: T;
  @Output() valueChange = new EventEmitter<T>();
  @Input() placeholder?: string;
  @Input() options: SelectOption[];
  @Input() disabled?: boolean;
  @Output() blur = new EventEmitter();

  normalizedOptions: NormalizedOption[];
  groups: Array<{ label: string; options: NormalizedOption[] }>;

  ngOnChanges(): void {
    const grouped = groupBy((option: SelectOption) => option.group || noGroupName, this.options);

    this.normalizedOptions = grouped[noGroupName];
    this.groups = Object.entries(grouped)
      .filter(([label]) => label !== noGroupName)
      .map(([label, options]) => ({ label, options }));
  }

  get isNoValueSelected(): boolean {
    return this.value === null || this.value === '';
  }
}

export interface SelectOption<T = string | number> extends NormalizedOption<T> {
  readonly group?: string;
}

interface NormalizedOption<T = string | number> {
  readonly id: T;
  readonly value: string;
}
