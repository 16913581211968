import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'k2Id',
    standalone: false
})
export class K2IdPipe implements PipeTransform {
  transform(entity: Entity) {
    return toK2Id(entity);
  }
}

export function toK2Id(entity: Entity): string | undefined {
  const e = entity as any;

  if (e == null) return;
  if (e.id != null) return `K2-${e.id}`;
  if (e.k2_id != null) return `K2-${e.k2_id}`;

  return `K2-${e}`;
}

type Entity = { id?: string | number; k2_id?: string | number } | string | number;
