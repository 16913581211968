import { Component, Input } from '@angular/core';
import { Partner } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { WithFormControl } from '@k2/common/k2-forms/field-control/utils';

@Component({
    selector: 'partner-finder-control',
    templateUrl: 'partner-finder-control.component.html',
    standalone: false
})
export class PartnerFinderControlComponent extends WithFormControl {
  @Input() field: Field<Partner>;

  get searchParams() {
    return this.field.attributes.searchParams;
  }

  get relatedServices() {
    return this.field.attributes.relatedServices;
  }
}
