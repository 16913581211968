import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { FilterOption } from "@k2/common/filters";

@Component({
    selector: 'user-search-filter',
    templateUrl: 'user-search-filter.component.html',
    styleUrls: ['user-search-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class UserSearchFilterComponent {
  @Input() value: number | string;
  @Input() options: FilterOption[];
  @Input() placeholder = 'Please select...';
  @Input() disabled = false;

  @Output() valueChange = new EventEmitter<number | string>();
  @Output() openedChange = new EventEmitter<boolean>();
  public filterCtrl: UntypedFormControl = new UntypedFormControl();

  changeValue() {
    this.filterCtrl.patchValue(null);
    this.valueChange.emit(this.value);
  }
}
