import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FieldTooltip } from '@k2/common/k2-forms-state/types';

@Component({
    selector: 'field-tooltip',
    templateUrl: 'field-tooltip.component.html',
    styleUrls: ['field-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FieldTooltipComponent {
  @Input() tooltip: FieldTooltip;
}
