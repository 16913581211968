import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'alert-bar',
    templateUrl: 'alert-bar.component.html',
    styleUrls: ['alert-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AlertBarComponent {
  @Input() noIcon = false;
  @Input() html: string;
}
