import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
    selector: 'initiation-nav-buttons',
    templateUrl: 'initiation-nav-buttons.component.html',
    styleUrls: ['initiation-nav-buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InitiationNavButtonsComponent {
  @Input() fallbackLink;
  @Input() submitLabel?: string;
  @Output() onSubmit = new EventEmitter<void>();
  @Output() onBack = new EventEmitter<void>();
  isLoading = false;

  constructor(private cd: ChangeDetectorRef) {}

  @Input()
  set loading(loading: boolean) {
    this.isLoading = loading;
    this.cd.markForCheck();
  }
}
