import { Pipe, PipeTransform } from '@angular/core';
import { LinkGenerator, LinkSpec } from './link-generator';

@Pipe({
    name: 'link',
    standalone: false
})
export class LinkPipe implements PipeTransform {
  constructor(private generator: LinkGenerator) {}

  transform(link?: LinkSpec): any {
    if (link == null) return;
    // TODO: Remove "as any" after we upgrade to TypeScript v3.6!
    return this.generator.generate(link as any);
  }
}
