import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, DoCheck,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { equals } from 'ramda';
import intlTelInput from 'intl-tel-input';
import { replaceAll } from '@k2/common/helpers';
import { default as intlTelInputUtils } from 'intl-tel-input/build/js/utils.js';

@Component({
    selector: 'telephone-input',
    templateUrl: 'telephone-input.component.html',
    styleUrls: ['telephone-input.component.scss'],
    standalone: false
})
export class TelephoneInputComponent implements AfterViewInit, DoCheck {
  @Input() id: string;
  @Input() initialCountry: string;
  @Input() value: string;
  @Input() disabled = false;

  @Output() valueChange = new EventEmitter<string>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() blur = new EventEmitter();

  currentValue = '';
  validNumber = true;
  intlTelInputInstance;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    let element; // need for checking if data sets to a new telephone input
    for (let item of document.getElementsByClassName('telephone-input')) {
      if (item.id === this.id && item.getAttribute('data-intl-tel-input-id') === null) {
        element = item;
      }
    }
    this.intlTelInputInstance = intlTelInput(element, {
      nationalMode: true,
      formatOnDisplay: true,
      separateDialCode: true,
      initialCountry: this.normalizeCountryCode(this.initialCountry),
      customPlaceholder(selectedCountryPlaceholder, _) {
        return replaceAll(selectedCountryPlaceholder,'-', ' ');
      }
    });
    if (this.value) {
      this._setValue(this.value);
    }
    element.addEventListener('countrychange', () => this.onInputChange());
  }

  ngDoCheck() {
    if(this.intlTelInputInstance) {
      if(!this.currentValue && this.value) {
        this._setValue(this.value);
      }
    }
  }

  onInputChange() {
    this.valueChange.emit(
      this.currentValue
        ? `+${
            this.intlTelInputInstance.getSelectedCountryData().dialCode
          }${this.currentValue.replace(' ', '')}`
        : ''
    );
    this._validate(this.currentValue.replace(' ', ''));
  }

  private _setValue(value: string) {
    this.intlTelInputInstance.setNumber(value);
    setTimeout(() => {
      this.currentValue = this.intlTelInputInstance
        .getNumber();
      this.currentValue
        .replace(`+${this.intlTelInputInstance.getSelectedCountryData().dialCode}`, '');
      this.cdr.detectChanges();
      this._validate(
        value.replace(`+${this.intlTelInputInstance.getSelectedCountryData().dialCode}`, '')
      );
    }, 500);
  }

  private _validate(value: string) {
    const DIAL_CODE = this.intlTelInputInstance.getSelectedCountryData().dialCode;
    const VAL = this.intlTelInputInstance
      .telInput.value
      .replaceAll(`+${DIAL_CODE}`, '');

    this.validNumber =
      !value.includes('-') &&
      !isNaN(Number(value)) &&
      VAL.length === value.length &&
      intlTelInputUtils.isValidNumber(`+${DIAL_CODE}${this.currentValue}`);
    this.isValid.emit(this.validNumber);
  }

  /**
   * Returns a normalized country code.
   *
   * If a given `code` is not supported, then the 'gb' will be returned.
   */
  normalizeCountryCode(iso: string): string {
    if (!iso) return 'gb';

    const availableIso = intlTelInput.getCountryData().map(c => c.iso2);
    const NORMALIZED = iso.toLowerCase();

    return availableIso.some(equals(NORMALIZED)) ? NORMALIZED : 'gb';
  }
}
