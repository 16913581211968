import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'service-status-indicator',
    templateUrl: 'service-status-indicator.component.html',
    styleUrls: ['service-status-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ServiceStatusIndicatorComponent {
  @Input() status: string;
  @Input() tooltip?: string;
}
