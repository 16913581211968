import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator } from '@angular/forms';
import { emptyOr, floatValidator } from '@k2/common/k2-forms/validators';

/**
 * Use this directive to add float validation to the plain <input> element.
 *
 * EXAMPLE:
 *   <input float [(ngModel)]="model" />
 *
 */
@Directive({
    selector: '[float]',
    providers: [{ provide: NG_VALIDATORS, useExisting: FloatValidatorDirective, multi: true }],
    standalone: false
})
export class FloatValidatorDirective implements Validator {
  validate = emptyOr(floatValidator);
}
