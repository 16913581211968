import { Component } from '@angular/core';
import { SimpleTabComponent } from './simple-tab.component';

@Component({
    selector: 'simple-tabs',
    templateUrl: 'simple-tabs.component.html',
    styleUrls: ['simple-tabs.component.scss'],
    standalone: false
})
export class SimpleTabsComponent {
  tabs: SimpleTabComponent[] = [];

  selectTab(tab: SimpleTabComponent) {
    this.tabs.forEach(tab => {
      tab.active = false;
    });
    tab.active = true;
  }

  addTab(tab: SimpleTabComponent) {
    if (this.tabs.length === 0) {
      tab.active = true;
    }
    this.tabs.push(tab);
  }

  removeTab(tab: SimpleTabComponent) {
    this.tabs = this.tabs.filter(t => t !== tab);
  }
}
