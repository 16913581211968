import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HComponent } from '@k2/common/ui/components/heading/h.component';

@Component({
    selector: 'h5',
    templateUrl: 'h.component.html',
    styleUrls: ['h.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class H5Component extends HComponent {}
