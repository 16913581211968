import { Component, Input, OnDestroy } from '@angular/core';
import { SimpleTabsComponent } from './simple-tabs.component';

@Component({
    selector: 'simple-tab',
    template: `
    <div [hidden]="!active">
      <ng-content></ng-content>
    </div>
  `,
    standalone: false
})
export class SimpleTabComponent implements OnDestroy {
  @Input() tabTitle: string;
  active = false;

  constructor(public tabs: SimpleTabsComponent) {
    this.tabs.addTab(this);
  }

  ngOnDestroy() {
    this.tabs.removeTab(this);
  }
}
