import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { isNotEmpty, Subscriptions } from '@k2/common/helpers';
import { Field, ValueOption } from '@k2/common/k2-forms-state/types';
import { toggleDisabled, WithFormControl } from '@k2/common/k2-forms/field-control/utils';
import { emailValidator } from '@k2/common/k2-forms/validators';
import { uniq } from 'ramda';

@Component({
    selector: 'recipients-control',
    templateUrl: 'recipients-control.component.html',
    styleUrls: ['recipients-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RecipientsControlComponent extends WithFormControl<string[]> implements OnChanges, OnInit, OnDestroy {

  @Input() field: Field<string[]>;

  customControl = new UntypedFormControl('');
  private readonly subscriptions = new Subscriptions();

  ngOnInit() {
    this.subscriptions.add(
      this.customControl.valueChanges.subscribe(customValue => {
        const items = this.value || [];
        const optionItems = items.filter(item => this.isOption(item));
        const customItems = customValue
          .split(',')
          .map(item => item.trim())
          .filter(isNotEmpty);

        this.field.control.setValue(uniq([...optionItems, ...customItems]));
      })
    );
  }

  ngOnChanges(): void {
    this.field.control.setValidators([...(this.field.control.validator ? [this.field.control.validator] : []), this.emailsValidator]);
    this.field.control.registerOnDisabledChange(toggleDisabled(this.customControl));

    toggleDisabled(this.customControl)(this.field.control.disabled);

    this.field.control.registerOnChange(this.handleFieldValueChange.bind(this));
    this.handleFieldValueChange(this.field.control.value);
  }

  private handleFieldValueChange(value = []) {
    if (value == null) value = [];
    let customValue = value.filter(item => !this.isOption(item)).join(', ');

    if (this.customControl.value.endsWith(',')) customValue += ',';
    this.customControl.setValue(customValue, { emitEvent: false });
  }

  get options(): ValueOption[] {
    return this.field.attributes.valueOptions || [];
  }

  private isOption(value: any): boolean {
    return this.options.some(({ id }) => id === value);
  }

  private emailsValidator(control: AbstractControl) {
    return (control.value || [])
      .map(value => ({ value }))
      .map(emailValidator)
      .reduce((errors, error) => ({ ...errors, ...error }), {});
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
