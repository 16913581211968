import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Backend } from '@k2/common/backend/backend';
import { Subscriptions } from '@k2/common/helpers';
import { Paginator } from '@k2/common/pagination/paginator';
import { formSpecToFields } from '@k2/common/k2-forms/fields';
import { map } from 'rxjs/operators';
import { ClientAssignmentComponent } from '@k2/staff/content/clients/client/assignment/client-assignment.component';
import { FormDialogService } from '@k2/common/k2-forms/form-dialog/form-dialog.service';
import { ServiceIdentity } from '@k2/staff/content/clients/client/assignment/common/service-identity';
import {
  CostSavingsFieldsComponent
} from '@k2/staff/content/clients/client/assignment/common/cost-savings/cost-savings-fields.component';
import { CostSaving } from '@k2/common/entities-state/types';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'cost-savings',
    templateUrl: 'cost-savings.component.html',
    styleUrls: ['cost-savings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CostSavingsComponent implements OnDestroy {

  @Input() costSavings: CostSaving[];
  @Input() serviceIdentity: ServiceIdentity;
  @Input() serviceId: number;
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();

  underConfirmation = false;
  subscriptions = new Subscriptions();
  paginator = Paginator.custom({ pageSize: 5, keepOffsetAfterLengthChange: true });

  constructor(private dialog: FormDialogService,
              private backend: Backend,
              private assignmentRef: ClientAssignmentComponent) {
  }

  addCostSaving(serviceId: number) {
    const fields = this.backend.services
      .fetchNewCostSavingForm(this.assignmentRef.identity, serviceId)
      .pipe(
        map(data => data.form),
        formSpecToFields
      );

    this.subscriptions.add(
      this.dialog
        .openForm({
          title: 'New cost saving',
          submitLabel: 'Add cost saving',
          fields,
          fieldsCmp: {
            component: CostSavingsFieldsComponent,
            inputs: { fields }
          },
          onSubmit: value => lastValueFrom(this.backend.services
            .addNewCostSaving(this.assignmentRef.identity, serviceId, value))
        }).subscribe(response => this.update.emit(response))
    );
  }

  deleteCostSaving(costSavingId: number) {
    this.delete.emit({ ...this.assignmentRef.identity, serviceId: this.serviceId, costSavingId });
  }

  updateCostSaving(costSavingId: number) {
    const fields = this.backend.services
      .updateCostSaving(this.assignmentRef.identity, this.serviceId, costSavingId)
      .pipe(map(data => data.form), formSpecToFields);

    this.subscriptions.add(
      this.dialog
        .openForm({
            title: 'Edit cost saving',
            submitLabel: 'Save changes',
            fields,
            fieldsCmp: {
              component: CostSavingsFieldsComponent,
              inputs: { fields }
            },
            onSubmit: value => lastValueFrom(this.backend.services
              .updateCostSavingEvent(this.assignmentRef.identity, this.serviceId, costSavingId, value))
          }
        ).subscribe(costSavings => this.update.emit(costSavings))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
