import { Component } from '@angular/core';

@Component({
    templateUrl: 'page-not-found.component.html',
    standalone: false
})
export class PageNotFoundComponent {
  get year(): number {
    return new Date().getFullYear();
  }
}
