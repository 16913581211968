import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'edit-delete-options',
    templateUrl: 'edit-delete-options.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditDeleteOptionsComponent {
  @Input() deleteLink?: string;
  @Input() editLink?: string;
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();
}
