import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Workflow } from '@k2/common/entities-state/types';
import { isEmpty } from 'ramda';
import { select } from '@ngrx/store';
import { getSessionUser } from '@k2/common/sessions-state/reducers';
import { first } from 'rxjs/operators';
import { isNotNil } from '@k2/common/helpers';
import { AppStore } from '@k2/common/state/services/app-store';
import { ClientAppState } from '@k2/client/client-app-state';

@Component({
    selector: 'assignment-workflows-review',
    templateUrl: 'assignment-workflows-review.component.html',
    styleUrls: ['assignment-workflows-review.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssignmentWorkflowsReviewComponent {
  @Input() workflows: Workflow[];
  @Input() hhgWorkflow: Workflow;
  @Input() statusLink?: any;
  @Input() toServiceLink?: any;

  isClientAdmin: boolean = false;

  constructor(private store: AppStore<ClientAppState>) {
    this.store.pipe(select(getSessionUser), first(isNotNil)).subscribe(user => {
      this.isClientAdmin = user.role_name === 'CLIENT_ADMIN';
    });
  }

  get hasServices(): boolean {
    return this.hhgWorkflow !== null || this.workflows.some(workflow => !isEmpty(workflow.services));
  }

  isVisible(id: number): boolean {
    return id !== 38 || !this.isClientAdmin;
  }
}
