import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Todo } from '@k2/common/ui/components/todo/todo.component';

@Component({
    templateUrl: 'todos.component.html',
    styleUrls: ['../message.scss', '../error-message.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TodosComponent {
  @Input() todos: Todo[];
}
