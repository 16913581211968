import { Component, Input, OnChanges } from '@angular/core';
import { K2User } from '@k2/common/entities-state/types';
import { Field } from '@k2/common/k2-forms-state/types';
import { K2UsersSelectorConfig } from '@k2/common/k2-users/components/k2-users-selector/k2-users-selector.component';
import { isEmpty, isNil } from 'ramda';

@Component({
    selector: 'k2-users-selector-control',
    templateUrl: 'k2-users-selector-control.component.html',
    standalone: false
})
export class K2UsersSelectorControlComponent implements OnChanges {
  @Input() field: Field<K2User | K2User[]>;
  @Input() onlyTeam?: boolean = false;

  selected: K2User[];
  config: K2UsersSelectorConfig;

  ngOnChanges(): void {
    const value: any = this.field.defaultValue;
    const multiple = Array.isArray(value);

    this.config = {
      multiple,
      excluded: this.field.attributes.excluded || [],
      placeholder: this.field.attributes.placeholder
    };

    if (multiple) {
      this.selected = value;
    } else {
      this.selected = isEmpty(value) || isNil(value) ? [] : [value];
    }
  }

  update = (selected: K2User[]) => {
    this.selected = selected;

    if (this.config.multiple) {
      this.field.control.patchValue(selected);
    } else {
      this.field.control.patchValue(selected[0] || null);
    }
  };
}
