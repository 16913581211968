import { Pipe, PipeTransform } from '@angular/core';
import { safe } from '@k2/common/helpers';
import { Assignment } from '@k2/common/entities-state/types';
import { LinkGenerator } from './link-generator';

@Pipe({
    name: 'assigneeLink',
    standalone: false
})
export class AssigneeLinkPipe implements PipeTransform {
  constructor(private generator: LinkGenerator) {}

  transform(assignment: Assignment): any {
    if (assignment == null) return;

    return this.generator.generate({
      type: 'assignee',
      data: {
        clientId: assignment.client.id,
        assignmentId: assignment.id,
        assigneeId: safe(() => assignment.person.id)
      }
    });
  }
}
