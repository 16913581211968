import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LinkedAssignment } from '@k2/common/entities-state/types';

@Component({
    selector: 'assignment-autocomplete-option',
    templateUrl: 'assignment-autocomplete-option.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssignmentAutocompleteOptionComponent {
  @Input() linkedAssignment: LinkedAssignment;
  @Input() highlight: string;
}
