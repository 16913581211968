import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ensureObservable, ReplayLastSubject } from '@k2/common/helpers';
import { getPermissions } from '@k2/common/permissions-state/reducers';
import { RootState as PermissionsRootState } from '@k2/common/permissions-state/state';
import { PermissionsGuard, toPermissionsGuard } from '@k2/common/permissions/permissions-guard';
import { AppStore } from '@k2/common/state/services/app-store';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { Todo } from '../todo/todo.component';

@Component({
    selector: 'side-navigated-content',
    templateUrl: 'side-navigated-content.component.html',
    styleUrls: ['side-navigated-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SideNavigatedContentComponent implements OnChanges {
  @Input() label?: string;
  @Input() additionalLabel?: string;
  @Input() hasTodoList?: boolean;
  @Input() items: SideNavItem[] | Observable<SideNavItem[]>;
  @Input() additionalItems?: SideNavItem[] | Observable<SideNavItem[]>;
  @Input() compact = false;
  @Input() service: string;
  visibleItems: Observable<Item[]>;
  additionalVisibleItems: Observable<Item[]>;
  todos = new ReplayLastSubject<Todo[]>();

  constructor(private store: AppStore<PermissionsRootState>) {}

  ngOnInit() {}

  ngOnChanges(): void {
    const items = ensureObservable(this.items);
    const permissions = this.store.pipe(select(getPermissions));

    this.visibleItems = items.pipe(
      map(items => items.map(toGuarderItem)),
      withLatestFrom(permissions),
      map(([guardedItems, availablePermissions]) =>
        guardedItems.filter(item => item.guard(availablePermissions))
      )
    );

    if (this.additionalItems != null) {
      const additionalItems = ensureObservable(this.additionalItems);

      this.additionalVisibleItems = additionalItems.pipe(
        map(items => items.map(toGuarderItem)),
        withLatestFrom(permissions),
        map(([guardedItems, availablePermissions]) =>
          guardedItems.filter(item => item.guard(availablePermissions))
        )
      );
    }
  }
}

function toGuarderItem(item: SideNavItem): GuardedItem {
  const { permissions, ...rest } = item;

  return {
    ...rest,
    guard: permissions == null ? () => true : toPermissionsGuard(permissions)
  };
}

export interface SideNavItem extends Item {
  readonly permissions?: string;
}

interface GuardedItem extends Item {
  readonly guard: PermissionsGuard;
}

interface Item {
  readonly label: string;
  readonly link: any;
  readonly exact?: boolean;
  readonly queryParams?: { [name: string]: string };
}
