import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { countries, states } from '@k2/common/countries';
import { K2SelectOption } from '@k2/common/k2-select/k2-select-option';
import { map, pipe, toPairs } from 'ramda';

/**
 * Provides a combination of country and state selects.
 */
@Component({
    selector: 'place-selector',
    templateUrl: 'place-selector.component.html',
    styleUrls: ['place-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PlaceSelectorComponent {
  @Input() place: Place;
  @Input() disabled = false;
  @Output() placeChange = new EventEmitter();
  readonly countries: K2SelectOption[];

  constructor() {
    this.countries = toOptions(countries);
  }

  get states(): K2SelectOption[] {
    const countryStates = states[this.place.country];
    if (countryStates == null) return null;
    return toOptions(countryStates);
  }

  changeCountry = (country: string) => {
    this.placeChange.emit({ country, state: null });
  };

  changeState = (state: string) => {
    const { country } = this.place;
    this.placeChange.emit({ country, state });
  };
}

const toOptions = pipe(
  toPairs,
  map(([id, text]: [string, string]) => ({ id, text }))
);

export interface Place {
  readonly country: string;
  readonly state: string | null;
}
