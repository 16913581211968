import { Component, Input, OnChanges } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';
import { Airport } from '@k2/common/ui/components/airports/airport';
import { isEmpty, isNil } from 'ramda';

@Component({
    selector: 'airport-selector-control',
    templateUrl: 'airport-selector-control.component.html',
    standalone: false
})
export class AirportSelectorControlComponent implements OnChanges {
  @Input() field: Field<Airport>;

  selected: Airport[];

  ngOnChanges(): void {
    const value: Airport = this.field.defaultValue;
    this.selected = isEmpty(value) || isNil(value) ? [] : [value];
  }

  update = (selected: Airport[]) => {
    this.selected = selected;
    this.field.control.patchValue(selected[0] || null);
  };
}
