import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeAssignmentStatus } from '@k2/common/assignments-state/actions';
import { RefType } from '@k2/common/entities-state/types';
import { ActionDispatcher } from '@k2/common/state/services/action-dispatcher';
import { Backend } from '@k2/common/backend/backend';

@Component({
    selector: 'assignment-status-bar',
    templateUrl: 'assignment-status-bar.component.html',
    styleUrls: ['assignment-status-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssignmentStatusBarComponent {
  @Input() assignment: any;
  @Input() readOnly: boolean = false;
  @Output() statusEvent = new EventEmitter<any>();

  constructor(private actions: ActionDispatcher, private backend: Backend) {}

  get statuses(): RefType[] {
    return this.assignment.possible_statuses;
  }

  get currentStatus(): RefType {
    return this.assignment.status;
  }

  isActive = (status: RefType): boolean => {
    return this.currentStatus.type_value === status.type_value;
  };

  get isEditable(): boolean {
    return this.readOnly || this.isCompleted;
  }

  get completedStatus(): RefType {
    return this.statuses.find(status => status.type_value === 'COMPLETED');
  }

  get uncompletedStatuses(): RefType[] {
    return this.statuses.filter(status => status.type_value !== 'COMPLETED');
  }

  tryUpdate = (newStatus: RefType) => {
    if (newStatus.type_value !== this.currentStatus.type_value) {
      const clientId = this.assignment.client.id;
      const assignmentId = this.assignment.id;
      const statusId = newStatus.id;
      this.backend.clientAssignments
        .patchAssignmentStatus({ clientId, assignmentId }, statusId)
        .subscribe(assignment => {
          this.actions.dispatch(new ChangeAssignmentStatus(this.assignment.id, newStatus));
          this.statusEvent.emit(assignment);
        })
    }
  };

  private get isCompleted(): boolean {
    return this.currentStatus.type_value === 'COMPLETED';
  }
}
