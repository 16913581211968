<section>
  <div class="block-frame">
    <div class="row">
      <div class="col-sm-12">
        <div class="name-wrap clearfix">
          <h3 *ngIf="!isAssignmentEncrypted" class="assignee-header">
            <a *ngIf="assigneeLink" [routerLink]="assigneeLink">
              {{ assignee | userName }}
            </a>
            <span *ngIf="!assigneeLink">{{ assignee | userName }}</span>
          </h3>

          <span *ngIf="hasFamily" class="family-indicator">(and family)</span>
          <tag *ngIf="isNotDefault" [value]="assignmentServiceLevelTag"></tag>
          <span *ngIf="assignee.has_any_claim" matTooltipPosition="after" [matTooltip]="'Previous insurance claim recorded'">
            <img src="broken_glass.png" class="broken-glass" style="  height: fit-content; width: 25px;"/>
            <span class="sr-only"></span>
          </span>
          <p>{{ assignee.job_title }}</p>
          <p>{{ assignee.subsidiary.name }}</p>
        </div>

        <assignee-family-card
          [assignee]="assignee"
          [assigneeLink]="assigneeLink"
          [familyLink]="familyLink"
          [petsLink]="petsLink"
          [isAssignmentEncrypted]="isAssignmentEncrypted"
        ></assignee-family-card>
      </div>
      <div class="contact-wrap">
        <div class="col-sm-5">
          <dl class="details">
            <dt>
              <icon type="phone-alt"></icon>
              <span>&nbsp;Home:</span>
            </dt>
            <dd>
              <a
                *ngIf="assignee.telephone_home && !isAssignmentEncrypted"
                href="tel:{{ assignee.telephone_home }}"
              >
                {{ assignee.telephone_home | telephone }}
              </a>
              <empty *ngIf="!assignee.telephone_home || isAssignmentEncrypted">unknown</empty>
            </dd>

            <dt>
              <icon type="briefcase"></icon>
              <span>&nbsp;Work:</span>
            </dt>
            <dd>
              <a
                *ngIf="assignee.telephone_work  && !isAssignmentEncrypted"
                href="tel:{{ assignee.telephone_work }}"
              >
                {{ assignee.telephone_work | telephone }}
              </a>
              <empty *ngIf="!assignee.telephone_work || isAssignmentEncrypted">unknown</empty>
            </dd>
          </dl>
        </div>
        <div class="col-sm-7">
          <dl class="details">
            <dt>
              <icon type="phone"></icon>
              <span>&nbsp;Mobile:</span>
            </dt>
            <dd>
              <a
                *ngIf="assignee.telephone_mobile && !isAssignmentEncrypted"
                href="tel:{{ assignee.telephone_mobile }}"
              >
                {{ assignee.telephone_mobile | telephone }}
              </a>
              <empty *ngIf="!assignee.telephone_mobile || isAssignmentEncrypted">unknown</empty>
            </dd>

            <dt>
              <icon type="envelope"></icon>
              <span>&nbsp;Email:</span>
            </dt>
            <dd>
              <a *ngIf="assignee.email && !isAssignmentEncrypted" href="mailto:{{ assignee.email }}">
                {{ assignee.email }}
              </a>
              <empty *ngIf="!assignee.email || isAssignmentEncrypted">unknown</empty>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</section>
