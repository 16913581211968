import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Pet } from '@k2/common/entities-state/types';

@Component({
    selector: 'assignee-family-pet-icon',
    templateUrl: 'assignee-family-pet-icon.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssigneeFamilyPetIconComponent {
  @Input() pet: Pet;
  @Input() link: any;

  get name(): string {
    if (this.pet.species != null) return this.pet.species;
    return 'Unspecified';
  }

  get isIncomplete(): boolean {
    return this.pet.species == null;
  }
}
