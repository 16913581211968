import { Component, EventEmitter, Input, Output } from '@angular/core';
import { memoize } from 'lodash';

@Component({
    selector: 'status-section',
    styleUrls: ['status-section.component.scss'],
    templateUrl: 'status-section.component.html',
    standalone: false
})
export class StatusSectionComponent {
  @Input() statuses: any[];
  @Output() update = new EventEmitter<MilestoneStatusIdentity>();

  toMilestonesModel = memoize(milestones => {
    return milestones.map(milestone => {
      return { value: milestone.id, label: milestone.label };
    });
  });

  findCurrentMilestoneId = (
    milestones: Array<{ id: string; completed_at: string | null }>
  ): string | null => {
    let completed = milestones.filter(milestone => {
      return milestone.completed_at;
    });

    if (completed.length > 0) return completed[completed.length - 1].id;
    return null;
  };

  updateMilestoneStatus = (collectionId: string, elementId: string, milestoneId: string) => {
    this.update.emit({
      collectionId,
      elementId,
      milestoneId
    });
  };
}

export interface MilestoneStatusIdentity {
  collectionId: string;
  elementId: string;
  milestoneId: string;
}
