import { Component, Input, OnChanges } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Field } from '@k2/common/k2-forms-state/types';
import { addValidators } from '@k2/common/k2-forms/field-control/utils';

@Component({
    selector: 'number-control',
    templateUrl: 'number-control.component.html',
    standalone: false
})
export class NumberControlComponent implements OnChanges {
  @Input() field: Field<number>;

  ngOnChanges(): void {
    addValidators(this.field.control, this.customValidator);
  }

  private customValidator = ({ value }: AbstractControl): ValidationErrors => {
    const { min = -Infinity, max = Infinity } = this.field.attributes;

    if (value < min) return { min: `Field value cannot be less than ${min}` };
    if (value > max) return { max: `Field value cannot be greater than ${max}` };
  };

  get step(): number {
    const defaultStep = this.hasToBeInteger ? 1 : 0.0001;
    return this.field.attributes.step || defaultStep;
  }

  private get hasToBeInteger(): boolean {
    const validators = this.field.validators || [];
    return validators.some(validator => validator.rule === 'IsInteger');
  }
}
