import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { take } from 'rxjs/operators';

class ConfirmationDialogData {
  onSubmit: () => void;
  onCancel: () => void;
  text: string;
  subtext?: string;
  confirmButtonText: string;
  cancelButtonText: string;
  hideCancelBtn: boolean; // false by default
  disableClose: boolean; // true by default
  alignTextLeft: boolean; // false by default
}

@Component({
    selector: 'confirmation-dialog-modal',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    standalone: false
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {
    dialogRef.disableClose = this.data.disableClose !== undefined ? this.data.disableClose : true;
  }

  ngOnInit() {
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          if (this.data.onSubmit) {
            this.data.onSubmit();
          }
        } else if (res !== undefined && this.data.onCancel) {
          this.data.onCancel();
        }
      });
  }
}
