import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Assignment } from '@k2/common/entities-state/types';

@Component({
    selector: 'assignments-ending-soon-table',
    templateUrl: 'assignments-ending-soon-table.component.html',
    styleUrls: ['assignments-soon-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AssignmentEndingSoonTableComponent {
  @Input() items: Assignment[];
}
