import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'pet-icon',
    templateUrl: 'pet-icon.component.html',
    styleUrls: ['pet-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PetIconComponent {
  @Input() hasWarning: boolean;
}
