import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { isElementEmpty } from '@k2/common/helpers';

/**
 * Renders a given children elements.
 *
 * When no meaningful child is given, then renders the "unanswered" message.
 */
@Component({
    selector: 'answer',
    templateUrl: 'answer.component.html',
    styleUrls: ['answer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AnswerComponent {
  @ViewChild('portal', { static: true }) portal: ElementRef<HTMLSpanElement>;

  get hasNoAnswer(): boolean {
    return isElementEmpty(this.portal.nativeElement);
  }
}
