import { Component, Input, OnInit } from '@angular/core';
import { Field } from '@k2/common/k2-forms-state/types';

@Component({
    selector: 'rating-control',
    templateUrl: 'rating-control.component.html',
    styleUrls: ['rating-control.component.scss'],
    standalone: false
})
export class RatingControlComponent implements OnInit {
  @Input() field: Field<number>;

  options: number[] = [];

  ngOnInit() {
    if (this.field.attributes.ratingPoints) {
      for (let i = 1; i <= this.field.attributes.ratingPoints; i++) {
        this.options.push(i);
      }
    } else {
      this.options = [1, 2, 3, 4];
    }
  }

  onStarClick(option: number) {
    if (this.field.control.enabled) {
      this.field.control.patchValue(option);
    }
  }
}
