import { Component, HostBinding, Input, Optional, SkipSelf } from '@angular/core';

/**
 * Section component, which can provide a level to its children.
 *
 * Nesting a section components will increase their levels. Section nested in
 * another section will always have a level equal to "parent.level + 1".
 * First section in a hierarchy will start with level 2.
 *
 * See <heading> component for more details about its usage.
 *
 * EXAMPLE:
 *   <section>
 *     Level 2
 *     <section>
 *       Level 3
 *     </section>
 *   </section>
 */
@Component({
    selector: 'section',
    templateUrl: 'section.component.html',
    styleUrls: ['section.component.scss'],
    standalone: false
})
export class SectionComponent {
  // Overrides a section level
  @Input('level') _level?: number;

  @Input('outline') _outline: undefined | '';

  constructor(
    @SkipSelf()
    @Optional()
    private parent?: SectionComponent
  ) {}

  @HostBinding('class.outline')
  get outline(): boolean {
    return this._outline === '';
  }

  get level(): number {
    if (this._level != null) return this._level;
    return this.parent ? this.parent.level + 1 : 2;
  }
}
