import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'negate',
    standalone: false
})
export class NegatePipe implements PipeTransform {
  transform(value: boolean): boolean {
    return !value;
  }
}
