import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Partner } from '../../../../entities-state/types';

@Component({
    selector: 'partner-ingot-base',
    templateUrl: 'partner-ingot-base.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PartnerIngotBaseComponent {
  @Input() partner: Partner;
}
