import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

/**
 * Provides a remove functionality for any component.
 *
 * EXAMPLE:
 *   <removable (remove)="remove()">
 *     <ingot></ingot>
 *   </removable>
 */
@Component({
    selector: 'removable',
    templateUrl: 'removable.component.html',
    styleUrls: ['removable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RemovableComponent {
  @Output() remove = new EventEmitter();
}
