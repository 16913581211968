import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'address-selector-dialog',
    templateUrl: 'address-selector-dialog.component.html',
    styleUrls: ['address-selector-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddressSelectorDialogComponent {
  private countries: IdentifiableCountry[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddressSelectorDialogData,
    private dialogRef: MatDialogRef<AddressSelectorDialogComponent>
  ) {
    this.countries = data.countries;
  }

  select = (address: Address) => {
    this.dialogRef.close(this.toOutput(address));
  };

  toOutput = (address): Selected => {
    return {
      address: {
        address1: address.address1,
        address2: address.address2,
        address3: address.address3,
        address4: address.address4,
        city: address.city,
        county: address.county,
        postal_code: address.postal_code
      },
      countryId: this.findCountryId(address)
    };
  };

  private findCountryId = (address: AddressWithCountry) => {
    const country = this.countries.find(option => option.value === address.countries_name);
    return country.id;
  };
}

export interface AddressSelectorDialogData {
  title: string;
  addresses: any[];
  countries: IdentifiableCountry[];
}

export type IdentifiableCountry = { id: number; value: string };

export interface Selected {
  address: Address;
  countryId: number;
}

interface Address {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  county: string;
  postal_code: string;
}

interface AddressWithCountry extends Address {
  countries_name: string;
}
